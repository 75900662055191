import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  label,
  maxLength,
  disabled,
  error = '',
  useIconError
}) => {
  const handleChange = e => {
    onChange(name, e.target.value);
  };

  const handleBlur = e => {
    if (typeof onBlur === 'function') {
      onBlur(name, e.target.value);
    }
  };

  const handleFocus = e => {
    if (typeof onFocus === 'function') {
      onFocus(name, e.target.value);
    }
  };
  let _id = Math.random(5000);
  const _label = label ? <label htmlFor={_id}>{label}</label> : '';

  const inp = (
    <React.Fragment>
      {_label}
      <input
        id={_id}
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        className={!useIconError && error !== '' ? 'required' : ''}
      />
    </React.Fragment>
  );

  if (error === '') {
    return <div>{inp}</div>;
  }
  if (useIconError) {
    return (
      <div className="input-icon">
        {inp}
        {error !== '' && <i className="icon-alert" />}
      </div>
    );
  } else {
    return (
      <div>
        {inp}
        <p className="small text-red">{error}</p>
      </div>
    );
  }
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  useIconError: PropTypes.bool
};

TextInput.defaultProps = {
  useIconError: true
};

export default TextInput;
