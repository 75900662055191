import React from 'react';

import { useTranslation } from './useTranslation';

export default function useProductBreads(products) {
  const { t, loaded } = useTranslation();

  return React.useMemo(() => {
    const PRODUCTS = products || [];
    let breads = [];
    PRODUCTS.forEach((product) => {
      breads.push({
        breadcrumb: product['fixedBread']
          ? product.fixedBread
          : t(`product_name_${product.productID}`) || t(product.label) || product.label,
        path: product.url || product.URL,
        id: product.productID,
        showTitle: product.showInNavigation,
      });
      if (product.children) {
        product.children.forEach((child) => {
          breads.push({
            breadcrumb: child['fixedBread']
              ? child.fixedBread
              : t(`product_name_${child.productID}`) || t(child.label) || child.label,
            path: child.url || child.URL,
            id: child.productID,
            showTitle: child.showInNavigation,
          });
        });
      }
    });
    return breads;
  }, [t, loaded]);
}
