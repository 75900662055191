import React from 'react';

export const SearchIcon = props => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4836 19.7917C16.0817 19.7917 19.8091 16.0607 19.8091 11.4583C19.8091 6.85596 16.0817 3.125 11.4836 3.125C6.88563 3.125 3.1582 6.85596 3.1582 11.4583C3.1582 16.0607 6.88563 19.7917 11.4836 19.7917Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8905 21.875L17.3635 17.3438"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
