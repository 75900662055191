import React from 'react';
import Cookies from 'js-cookie';
import Modal from 'jsx/elements/modals/RModal';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import Button from 'jsx/elements/buttons/Button';
import Checkbox from 'jsx/elements/inputs/Checkbox';
import ClearDoubleArrow from 'jsx/elements/buttons/ClearDoubleArrow';
import Flex from 'jsx/layout/Flex';
import { useTranslation } from 'jsx/hooks/useTranslation';

export const CookieProvider = ({ children, waitForAuth = true }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { cookieState, showModal, shownDialog } = state;
  const { t, loaded } = useTranslation(2011);
  const authContext = useAuthContext();

  const context = React.useMemo(() => {
    return { ...cookieState, shownDialog };
  }, [cookieState, shownDialog]);

  const accept = newState => dispatch({ type: 'ACCEPT', payload: newState });

  if (waitForAuth && authContext === null) {
    throw new Error('waitForAuth requires CookieProvider to be a child of AuthProvider');
  }

  const show = shownDialog ? false : waitForAuth ? authContext.isAuthenticated : true;
  if (!loaded) return null;

  return (
    <CookieStateContext.Provider value={context}>
      {children}
      {show && (
        <CookieBanner
          t={t}
          onAgree={() => accept({ analytics: true, essential: true })}
          onSettings={() => dispatch({ type: 'SHOW_MODAL' })}
        />
      )}
      <CookieModal
        t={t}
        isOpen={showModal}
        onAccept={accept}
        onClose={() => dispatch({ type: 'HIDE_MODAL' })}
      />
    </CookieStateContext.Provider>
  );
};

const CookieModal = ({ isOpen, onClose, onAccept, t }) => {
  const [state, setState] = React.useState({
    essential: true,
    analytics: false
  });

  const toggle = which => {
    setState(old => ({
      ...old,
      [which]: !old[which]
    }));
  };

  return (
    <Modal isOpen={isOpen}>
      <h2>{t('modal_title')}</h2>

      <p>{t('modal_body')}</p>

      <Checkbox
        id="essential"
        name="essential"
        value={true}
        onChange={() => null}
        label={t('modal_essential')}
      />
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Checkbox
          id="analytics"
          name="analytics"
          value={state.analytics}
          onChange={() => toggle('analytics')}
          label={t('modal_analytics')}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <ClearDoubleArrow
          arrowSide="left"
          newTab
          text={t('modal_moreText')}
          url={t('modal_moreLink')}
        />
      </div>
      <Flex justifyContent="space-between" style={{ marginTop: 20 }}>
        <Button color="clear" text={t('modal_close')} onClick={onClose} />
        <Button color="gray" text={t('modal_accept')} onClick={() => onAccept(state)} />
      </Flex>
    </Modal>
  );
};

const CookieBanner = ({ onSettings, onAgree, t }) => {
  return (
    <div style={backdropStyle}>
      <div
        dangerouslySetInnerHTML={{ __html: t('banner_text') }}
        style={{ marginBottom: '0.3rem' }}
      />
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Button color="red" text={t('banner_agree')} onClick={onAgree} />
      </div>
      <Button color="white" text={t('banner_settings')} onClick={onSettings} />
    </div>
  );
};

export const useCookieState = () => React.useContext(CookieStateContext);

const host = window.location.hostname;
const cookieDomain = host.indexOf('localhost') !== -1 ? 'localhost' : 'creditinfo.is';

export const COOKIE_KEYS = {
  analytics: 'ci_analytics',
  essential: 'ci_essential'
};

const CookieStateContext = React.createContext();

const analyticsCookie = Cookies.get(COOKIE_KEYS.analytics, { domain: cookieDomain });
const initialState = {
  cookieState: {
    analytics: analyticsCookie,
    essential: Cookies.get(COOKIE_KEYS.essential, { domain: cookieDomain })
  },
  showModal: false,
  shownDialog: analyticsCookie !== undefined
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'HIDE_MODAL':
      return { ...state, showModal: false };
    case 'SHOW_MODAL':
      return { ...state, showModal: true };
    case 'ACCEPT':
      Object.keys(payload)
        .filter(key => payload[key] === true)
        .forEach(key => {
          Cookies.set(COOKIE_KEYS[key], true, {
            expires: 365,
            path: '/',
            domain: cookieDomain
          });
        });
      return {
        cookieState: payload,
        showModal: false,
        shownDialog: true
      };
    default:
      return state;
  }
};

const backdropStyle = {
  color: '#fff',
  zIndex: 999,
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  backgroundColor: '#333',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  padding: '20px 40px'
};
