import React from 'react';
import { useHistory } from 'react-router-dom';
import { getMonth, getYear } from 'date-fns';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  Legend
} from 'recharts';
import Spinner from 'jsx/elements/spinner';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import Button from 'jsx/elements/buttons/Button';
import useLocale from 'jsx/locale/useLocale';
import Flex from 'jsx/layout/Flex';
import { api } from 'api';
import { monthStrings } from 'constants/dates';
import {
  NewsArticles,
  NewsArticle,
  NewsArticleMediaIcon,
  NewsArticleContent,
  NewsArticleHeadline,
  NewsArticleText
} from './News';

export const NoAccess = () => {
  const { t } = useLocale();
  const [data, setData] = React.useState(null);
  const history = useHistory();

  React.useEffect(() => {
    api.getMonitorTeaser().then(setData);
  }, []);

  if (!data) return <Spinner />;

  const graphHdr = data.notEnoughData ? 'noaccess_graph_header_ci' : 'noaccess_graph_header';
  const newsHdr = data.notEnoughData ? 'noaccess_news_header_ci' : 'noaccess_news_header';

  return (
    <>
      <div>
        <h2 className="text-gray-dark text-24">{t('noaccess_header')}</h2>

        <p>{t('noaccess_cta')}</p>

        <Button text={t('noaccess_apply')} onClick={() => history.push('/request-access')} />
        <br />
        <br />
        <hr />
        <div className="grid gutter collapse720">
          <div className="col s1of1">
            <figure className="figure" align="middle">
              <img src="https://creditinfo.is/resources/images/gif/ipad-frame.gif" alt="" />
            </figure>
          </div>
        </div>
        <div className="grid gutter collapse720">
          <div className="col s1of6">
            <figure
              className="figure hide720 centered svg"
              data-png="https://creditinfo.is/resources/images/icon-thin-line/png/news.png"
            >
              <img
                src="https://creditinfo.is/resources/images/icon-thin-line/svg/news.svg"
                alt=""
              />
            </figure>
          </div>
          <div className="col s5of6">
            <h2>Fjölmiðlavaktin</h2>
            <p>
              Á hverjum degi eru fluttar fjöldinn allur af fréttum á Íslandi í fjölmörgum
              fjölmiðlum. Ef þú missir af mikilvægri umfjöllun um þitt fyrirtæki í getur það reynst
              þér dýrkeypt. Sein og óundirbúin viðbrögð við fjölmiðlaumfjöllun eru ófagleg og geta
              skaðað orðspor fyrirtækja til langs tíma. Með Fjölmiðlavakt Creditinfo er hægt að
              fylgjast með fjölmiðlaumfjöllun um hvaða fyrirtæki eða málefni sem er. Við sendum þér
              tölvupóst hvenær sem umfjöllun birtist í útvarpi, sjónvarpi, blöðum, netmiðlum og
              samfélagsmiðlum. Þannig tryggir þú góða yfirsýn yfir umfjöllun um málefnin sem þig
              varðar.{' '}
            </p>
            <a
              href="https://www.creditinfo.is/lausnir-og-gogn/fjolmidlar.aspx"
              title="nánar um Fjölmiðlavaktina"
              className="button clear icon-double-arrow-right"
              target="_blank"
            >
              {' '}
              Nánar um Fjölmiðlavaktina
            </a>
          </div>
        </div>

        {/* <Link to="/request-access">
      </Link> */}

        {(data.subscriberTrend.length > 0 || data.scripts.length > 0) && (
          <p className="text-18 text-semibold text-center gutter-top">{t(graphHdr)}</p>
        )}

        {data.subscriberTrend.length > 0 && (
          <Flex justifyContent="center" alignItems="center" className="gutter-bottom">
            <ComparisonGraph data={data.subscriberTrend} showCreditinfo={data.notenoughData} />
          </Flex>
        )}

        {data.scripts.length > 0 && (
          <div className="gutter-top">
            <h2 className="text-gray-dark text-24">{t(newsHdr)}</h2>
            <NewsArticles>
              {data.scripts.map((entry) => (
                <NewsArticle key={entry.scriptId}>
                  <NewsArticleMediaIcon mediaName={entry.mediaName} />
                  <NewsArticleContent>
                    <NewsArticleHeadline
                      date={entry.scriptDate}
                      mediaName={entry.mediaName}
                      title={entry.title}
                    />
                    <NewsArticleText>{entry.abstract}</NewsArticleText>
                  </NewsArticleContent>
                </NewsArticle>
              ))}
            </NewsArticles>
          </div>
        )}
      </div>
    </>
  );
};

export const ComparisonGraph = ({ data, showCreditinfo }) => {
  const { user } = useAuthContext();
  const sorted = data.sort((b, a) => b.id - a.id);
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={sorted} margin={{ top: 15, right: 30, left: 20 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          axisLine={false}
          height={70}
          dataKey="label"
          tickLine={false}
          interval={data.length < 15 ? 0 : 'preserveStartEnd'}
          tick={<AxisTick data={sorted} />}
        />
        <Legend formatter={() => (showCreditinfo ? 'Creditinfo Lánstraust hf.' : user.s_name)} />
        <YAxis tickLine={false} axisLine={false} />
        <Line strokeWidth="2" type="linear" dataKey="value" stroke="#C40F11" />
      </LineChart>
    </ResponsiveContainer>
  );
};
const AxisTick = (props) => {
  const { x, y, payload, data } = props;
  const id = String(data[payload.index].id);
  const yearStr = id.substr(0, 4);
  const mnStr = id.substr(4);
  const dt = new Date(Number(yearStr), Number(mnStr) - 1);
  const month = getMonth(dt);
  const year = getYear(dt);
  const { lang } = useLocale();
  const months = monthStrings[lang];

  return (
    <g transform={`translate(${x},${y})`} className="text-12">
      <text x={0} y={0} dy={16} fill="#333" textAnchor="middle">
        {months[month]}
      </text>
      <text x={0} y={20} dy={16} fill="#333" textAnchor="middle">
        {year}
      </text>
    </g>
  );
};
