import { useEffect, useState } from 'react';
import { getElementHeight, getVisibleHeight } from '../../utils/browser';

/**
 * Hook that figures out the empty space minimum height between
 * the header (.main-header) and footer (.main-footer) on the page
 * and returns the height;
 */
export default () => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    let calculatedHeight = getVisibleHeight();
    const header = document.getElementById('main-header');
    const footer = document.getElementById('main-footer');
    calculatedHeight -= getElementHeight(header) + getElementHeight(footer);
    setHeight(calculatedHeight);
  }, []);

  return height;
};
