import React from 'react';
import axios from 'axios';
import Callback from './CallbackV2';

const authContext = React.createContext(null);
export const useAuthContext = () => React.useContext(authContext);

export default ({ children, authConfig, history, restrict = false }) => {
  const config = parseConfig(authConfig);
  const [user, setUser] = React.useState(null);
  const interceptRef = React.useRef(false);
  const bufferRef = React.useRef([]);
  const iframeRef = React.useRef({
    started: false,
    sessionState: null,
    frame: null,
    frameOrigin: '',
    frameTimer: null,
    isLocal: window.location.href.indexOf('localhost') !== -1
  });

  const stateRef = React.useRef({
    wellKnown: null,
    accessToken: null,
    refreshToken: null
  });

  const isCb = history.location.pathname.endsWith('/_cb');
  const isAuthed = isAuthenticated(config);

  if (!interceptRef.current) {
    interceptAddAccessTokenToHeader(config, stateRef);
    interceptRetryOnUnAuthorized(config, bufferRef, retry);
    interceptRef.current = true;
  }

  function setRefreshToken(token) {
    stateRef.current.refreshToken = token;
  }

  function setAccessToken(token) {
    stateRef.current.accessToken = token;
  }

  function setUserFromPayload() {
    setUser(getIdTokenPayload(config));
  }

  if (isAuthed && !user) {
    setUserFromPayload();
    const at = getAccessTokenResponse(config);
    if (at) {
      setAccessToken(at.access_token);
      setRefreshToken(at.refresh_token);
    }
  }

  function retry() {
    retryBuffer(config, stateRef.current.refreshToken, setRefreshToken, setAccessToken, bufferRef);
  }

  if (!stateRef.current.wellKnown) {
    getWellKnown(config).then((wk) => (stateRef.current.wellKnown = wk));
  }

  const contextValue = React.useMemo(
    () => ({
      loginCallback: (hash) =>
        loginCallback(config, setRefreshToken, setAccessToken, iframeRef, hash),
      logOut: (state, pluri) => logOut(config, state, pluri),
      signIn: (stateToSend) => signIn(config, stateToSend),
      isAuthenticated: isAuthed,
      setAccessToken,
      setRefreshToken,
      setUser: setUserFromPayload,
      user,
      authMethod: user ? user.amr[0] : null
    }),
    [user, isAuthed]
  );

  if (!isCb && !isAuthed && restrict) {
    signIn(config, getLocationData(history.location));
    return null;
  }

  return (
    <authContext.Provider value={contextValue}>
      {isCb ? (
        <Callback history={history} />
      ) : typeof children === 'function' ? (
        children(contextValue)
      ) : (
        children
      )}
    </authContext.Provider>
  );
};

export const getLocationData = (location) => {
  let loc = location || window.location;
  return {
    path: loc.pathname,
    search: loc.search,
    hashId: loc.hash && loc.hash.replace('#', ''),
  };
};

const retryBuffer = async (config, refreshToken, setRefreshToken, setAccessToken, bufferRef) => {
  const success = await requestNewAccessToken(
    config,
    refreshToken,
    setRefreshToken,
    setAccessToken
  );
  if (success) {
    for (let i = 0; i < bufferRef.current.length; i++) {
      const item = bufferRef.current[i];
      axios(item.config).then((response) => item.resolve(response));
    }
    bufferRef.current = [];
  } else {
    logOut(config);
  }
};

const interceptRetryOnUnAuthorized = (config, bufferRef, retry) => {
  return axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (!error.response.config._retry) {
          error.response.config._retry = true;
          return new Promise((resolve) => {
            const bufferLength = bufferRef.current.push({
              resolve,
              config: error.response.config
            });
            if (bufferLength === 1) {
              retry();
            }
          });
        } else {
          logOut(config);
        }
      }
      return Promise.reject(error);
    }
  );
};

const logOut = (config, state, postLogoutRedirectUri) => {
  if (process.env.REACT_APP_DO_NOT_LOGOUT) {
    return;
  }
  getWellKnown(config).then((wk) => {
    let idToken = getIdToken(config);
    const endPoint = wk.end_session_endpoint;
    const idTokenStr = `id_token_hint=${idToken ? idToken.id_token : ''}`;
    let postLogoutRedirect = `post_logout_redirect_uri=${encodeURIComponent(
      config.postLogoutRedirectUri || ''
    )}`;
    if (postLogoutRedirectUri) {
      postLogoutRedirect = `post_logout_redirect_uri=${encodeURIComponent(postLogoutRedirectUri)}`;
    }
    let urlStr = `${endPoint}?${idTokenStr}&${postLogoutRedirect}`;
    if (state) {
      urlStr = `${urlStr}&state=${encodeURIComponent(state)}`;
    }
    clearLocalStorage(config);
    localStorage.removeItem(`${config.storageKey}_state`);
    localStorage.removeItem(`${config.storageKey}_nonce`);
    window.location.assign(urlStr);
  });
};

const interceptAddAccessTokenToHeader = (authConfig, stateRef) => {
  axios.interceptors.request.use(
    (config) => {
      const isAuthServer = config.url ? config.url.includes(authConfig.authUrl) : false;
      if (!isAuthServer) {
        const { accessToken } = stateRef.current;
        if (accessToken !== null) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const parseConfig = (config) => ({
  authUrl: config.authUrl || '',
  responseType: config.responseType || 'id_token code',
  clientId: config.clientId || '',
  redirectUri: config.redirectUri || `${window.location.protocol}//${window.location.host}/_cb`,
  scope: config.scope || '',
  secret: config.secret || '',
  website: config.website || 'Default',
  postLogoutRedirectUri:
    config.postLogoutRedirectUri || `${window.location.protocol}//${window.location.host}`,
  logoutUri: config.logoutUri,
  refresh_token: null,
  api_access_token: null,
  sessionCheckInterval: config.sessionCheckInterval || 2000,
  storageKey: `${config.clientId}_${config.authUrl}`
});

const isAuthenticated = (config) => {
  const idToken = getIdTokenPayload(config);
  if (idToken === null || !idToken.exp) {
    return false;
  }
  return new Date(idToken.exp * 1000) > new Date();
};

const getWellKnown = (config) => {
  return new Promise((resolve) => {
    let data = localStorage.getItem(`${config.storageKey}_wellKnown`);
    let wk;
    if (data === null) {
      axios(`${config.authUrl}/.well-known/openid-configuration`).then((response) => {
        wk = response.data;
        localStorage.setItem(`${config.storageKey}_wellKnown`, JSON.stringify(wk));
        resolve(wk);
      });
    } else {
      wk = JSON.parse(data);
      resolve(wk);
    }
  });
};

const getAccessTokenByRefreshToken = (config, refreshToken) => {
  return new Promise((resolve, reject) => {
    getWellKnown(config)
      .then((wk) => {
        const auth = btoa(`${config.clientId}:${config.secret}`);
        const headers = {
          Authorization: `Basic ${auth}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        const gt = 'grant_type=refresh_token';
        const refreshTokenStr = `refresh_token=${refreshToken}`;
        const scope = `scope=${encodeURI(config.scope)}`;
        const client = `client_id=${config.clientId}`;
        const query = [gt, refreshTokenStr, scope, client].join('&');

        const options = {
          headers: headers,
          method: 'post',
          data: query
        };

        return axios(wk.token_endpoint, options);
      })
      .then((response) => resolve(response.data))
      .catch((ex) => reject(ex));
  });
};

const getAccessTokenByCode = (config, code) => {
  return new Promise((resolve) => {
    getWellKnown(config)
      .then((wk) => {
        const auth = btoa(`${config.clientId}:${config.secret}`);
        const headers = {
          Authorization: `Basic ${auth}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        };
        const gt = 'grant_type=authorization_code';
        const codeStr = `code=${code}`;
        const clientId = `client_id=${encodeURI(config.clientId)}`;
        const rediruri = `redirect_uri=${encodeURI(config.redirectUri || '')}`;
        const query = [gt, codeStr, clientId, rediruri].join('&');

        const options = {
          headers: headers,
          method: 'post',
          data: query
        };

        return axios(wk.token_endpoint, options);
      })
      .then((response) => resolve(response.data));
  });
};

const decodeClientIDToken = (token) => {
  let result = {};
  const tokenParts = token.split('&');
  for (let i = 0; i < tokenParts.length; ++i) {
    const parts = tokenParts[i].split('=');
    if (parts[0] === 'scope') {
      result[parts[0]] = decodeURI(parts[1]);
    } else if (parts[0] === 'state') {
      result[parts[0]] = decodeURIComponent(parts[1]);
    } else {
      result[parts[0]] = parts[1];
    }
  }
  return result;
};

const urlBase64Decode = (str) => {
  let output;
  try {
    output = str.replace('-', '+').replace('_', '/');
  } catch (ex) {
    return '';
  }

  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }
  return window.atob(output);
};

const utf8_decode = (utftext) => {
  let string = '';
  let i = 0;
  let c = 0;
  let c2 = 0;
  let c3;

  while (i < utftext.length) {
    c = utftext.charCodeAt(i);

    if (c < 128) {
      string += String.fromCharCode(c);
      i += 1;
    } else if (c > 191 && c < 224) {
      c2 = utftext.charCodeAt(i + 1);
      string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = utftext.charCodeAt(i + 1);
      c3 = utftext.charCodeAt(i + 2);
      string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
      i += 3;
    }
  }
  return string;
};

const getDataFromToken = (token) => {
  if (!token) return {};
  let data = {};
  const encoded = token.split('.')[1];
  let decoded = urlBase64Decode(encoded);
  decoded = utf8_decode(decoded);

  if (decoded !== '') {
    data = JSON.parse(decoded);
  }
  return data;
};

const requestNewAccessToken = (config, refreshToken, setRefreshToken, setAccessToken) => {
  return new Promise((resolve) => {
    if (refreshToken !== null) {
      return getAccessTokenByRefreshToken(config, refreshToken)
        .then((data) => {
          setRefreshToken(data.refresh_token);
          setAccessToken(data.access_token);
          localStorage.setItem(`${config.storageKey}_accessToken`, JSON.stringify(data));
          resolve(true);
        })
        .catch((e) => {
          resolve(false);
          logOut(config);
        });
    }
    resolve(false);
  });
};

/**
 *
 */
const signIn = async (config, stateToSend) => {
  getWellKnown(config).then((wk) => {
    const authEndpoint = wk.authorization_endpoint;
    const rt = `response_type=${config.responseType}`;
    const cid = `client_id=${config.clientId}`;
    const redir = `redirect_uri=${config.redirectUri}`;
    const scope = `scope=${config.scope}`;
    const state = JSON.stringify(stateToSend);
    const stateStr = `state=${state}`.replace(/&/g, '%26'); // encode &
    const website = `website=${config.website}`;
    const nonce = `N${Math.random()}${Date.now()}`;
    const nonceStr = `nonce=${nonce}`;
    const query = [rt, cid, redir, scope, stateStr, website, nonceStr].join('&');
    const url = encodeURI(`${authEndpoint}?${query}`);

    localStorage.setItem(`${config.storageKey}_state`, state);
    localStorage.setItem(`${config.storageKey}_nonce`, nonce);

    window.location.assign(url);
  });
};

const getIdToken = (config) => {
  return _parseOrNull(`${config.storageKey}_idToken`);
};

const getAccessTokenResponse = (config) => {
  return _parseOrNull(`${config.storageKey}_accessToken`);
};

const getIdTokenPayload = (config) => {
  return _parseOrNull(`${config.storageKey}_idTokenPayload`);
};

const clearLocalStorage = (config) => {
  localStorage.removeItem(`${config.storageKey}_idToken`);
  localStorage.removeItem(`${config.storageKey}_accessToken`);
  localStorage.removeItem(`${config.storageKey}_wellKnown`);
  localStorage.removeItem(`${config.storageKey}_idTokenPayload`);
  //Why are we not removeing state and nonce?
  localStorage.removeItem(`${config.storageKey}_state`);
  localStorage.removeItem(`${config.storageKey}_nonce`);
};

const _parseOrNull = (jsonKey) => {
  if (jsonKey) {
    try {
      const token = localStorage.getItem(jsonKey) || '';
      return JSON.parse(token);
    } catch (ex) {
      return null;
    }
  }
  return null;
};

const loginCallback = (config, setRefreshToken, setAccessToken, iframeRef, hash) => {
  return new Promise((resolve, reject) => {
    const cbStr = hash.substr(1);
    const storedState = decodeURIComponent(localStorage.getItem(`${config.storageKey}_state`));
    const storedNonce = localStorage.getItem(`${config.storageKey}_nonce`);
    const tokenData = decodeClientIDToken(cbStr);
    const resultState = decodeURIComponent(tokenData.state);
    const idTokenPayload = getDataFromToken(tokenData.id_token);

    if (!tokenData.error) {
      if (resultState !== storedState) {
        //TODO - Má ekki prófa nýtt state. Ef mismatch - remove old state and save new to local storage. Svo tékka á
        reject({ error: 'state mismatch when parsing token' });
      } else if (storedNonce !== idTokenPayload.nonce) {
        reject({ error: 'nonce mismatch when parsing token' });
      } else {
        const currentDate = new Date();
        if (idTokenPayload.exp === undefined) {
          reject({ error: 'token is expired' });
          return;
        }
        const expires = new Date(idTokenPayload.exp * 1000);
        if (currentDate > expires) {
          reject({ error: 'token is expired' });
          return;
        } else {
          getAccessTokenByCode(config, tokenData.code)
            .then((data) => {
              localStorage.setItem(`${config.storageKey}_idToken`, JSON.stringify(tokenData));
              localStorage.setItem(`${config.storageKey}_accessToken`, JSON.stringify(data));
              localStorage.setItem(
                `${config.storageKey}_idTokenPayload`,
                JSON.stringify(idTokenPayload)
              );

              setRefreshToken(data.refresh_token);
              setAccessToken(data.access_token);

              startSessionIframe(config, iframeRef);
              try {
                const loc = JSON.parse(tokenData.state);
                let search = loc.search;
                //not sure why we decode before returning here
                let path = decodeURIComponent(loc.path); //.replace(/%26/g, '&');
                if (loc.search) {
                  path = `${path}${search}`;
                }
                if (loc.hashId) {
                  path = `${path}#${loc.hashId}`;
                }
                resolve(path);
              } catch (ex) {
                resolve('/');
              }
            })
            .catch(() => reject({ error: 'Got no access token' }));
        }
      }
    } else {
      clearLocalStorage(config);
      reject({ error: 'User has no access to this site' });
    }
  });
};

const startSessionIframe = (config, iframeRef) => {
  if (!iframeRef.current.started) {
    iframeRef.current.started = true;

    getWellKnown(config).then((wk) => {
      const iframeUrl = wk.check_session_iframe;
      if (!iframeRef.current.sessionState) {
        const idToken = getIdToken(config);
        iframeRef.current.sessionState = idToken.session_state;
      }

      const idx = iframeUrl.indexOf('/', iframeUrl.indexOf('//') + 2);
      iframeRef.current.frameOrigin = iframeUrl.substr(0, idx);

      const frame = document.createElement('iframe');
      frame.style.display = 'none';

      iframeRef.current.frame = frame;

      if (iframeRef.current.isLocal) frame.sandbox = 'allow-scripts ';
      frame.onload = () => {
        window.addEventListener('message', (e) => onMessage(config, iframeRef, e), false);
        startFrameTimer(config, iframeRef);
      };
      frame.src = iframeUrl;
      document.body.appendChild(frame);
    });
  }
};

const onMessage = (config, iframeRef, e) => {
  const { frameOrigin, frame } = iframeRef.current;
  if (!frame) return;

  if (e.origin === frameOrigin && e.source === frame.contentWindow) {
    if (e.data === 'changed') {
      stopFrameTimer(iframeRef);
      clearLocalStorage(config);
      signIn(config, getLocationData());
    }
  }
};

const startFrameTimer = (config, iframeRef) => {
  if (iframeRef.current.frameTimer !== null) {
    stopFrameTimer(iframeRef);
  }

  iframeRef.current.frameTimer = window.setInterval(() => {
    if (!iframeRef.current.frame || !iframeRef.current.frame.contentWindow) {
      return;
    }

    iframeRef.current.frame.contentWindow.postMessage(
      `${config.clientId} ${iframeRef.current.sessionState}`,
      iframeRef.current.frameOrigin
    );
  }, config.sessionCheckInterval);
};

const stopFrameTimer = (iframeRef) => {
  if (iframeRef.current.frameTimer) {
    window.clearInterval(iframeRef.current.frameTimer);
    iframeRef.current.frameTimer = null;
  }
};
