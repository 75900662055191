import React from 'react';
import { useLocale } from 'jsx/locale/LanguageProviderV2';

import MenuButton from 'jsx/layout/MenuButton/MenuButton';

export const LanguageButton = () => {
  const { lang, setLanguage } = useLocale();

  function toggleLang() {
    const newLang = lang === 'is' ? 'en' : 'is';
    setLanguage(newLang);
  }

  return (
    <MenuButton
      icon="icon-language"
      label={lang === 'is' ? 'Eng' : 'ísl'}
      onClick={toggleLang}
      isMenu={false}
    ></MenuButton>
  );
};
