import { useAuthContext } from './AuthProviderV2';

/**
 * Restrict viewing the children to only authenticated users.
 */
export default ({ children, shouldLogin = true, restrictRole = undefined }) => {
  console.warn('RestrictAccess is deprecated, use useRoleAccess hook instead');
  const { isAuthenticated, signIn, user } = useAuthContext();
  if (!isAuthenticated) {
    if (shouldLogin) {
      signIn({ path: window.location.pathname });
    }
    return null;
  }
  if (restrictRole && !hasRoleAccess(restrictRole, user)) {
    return null;
  }
  return children;
};

export const hasRoleAccess = (role, user) => {
  if (!user) return false;
  let roles = [];

  if (typeof user.role === 'object') {
    roles = user.role.map(role => Number(role));
  } else {
    roles = [Number(user.role)];
  }
  return roles.indexOf(Number(role)) !== -1;
};
