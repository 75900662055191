export const allColors = [
  '#C40F11',
  '#333333',
  '#406F8F',
  '#FFD004',
  '#FF7900',
  '#691A12',
  '#91054D',
  '#8A3886',
  '#00661D',
  '#E46E67',
  '#666666',
  '#5EA2CF',
  '#FFDE51',
  '#FFA14D',
  '#953919',
  '#C27093',
  '#AE47A9',
  '#92C86A',
  '#F09E9D',
  '#999999',
  '#A3C9E3',
  '#FFED9E',
  '#FFBC80',
  '#A6644E',
  '#E0A8C2',
  '#BE86BB',
  '#C6E6B1'
];

export const analysisColors = {
  '-1': '#C2282A',
  0: '#C4C4C4',
  100: '#8A3886',
  1: '#417732'
};

export const scoreColors = {
  1: '#A6D5F2',
  2: '#4B82AA',
  3: '#175C87',
  4: '#103E5B',
  5: '#05121A'
};
