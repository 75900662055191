import { useState, useEffect } from 'react';
import apiRequest from 'utils/api/apiRequest';
import { useAuthContext } from '../auth/AuthProviderV2';
import { useLocale } from '../locale/LanguageProviderV2';

const sharedUrl = process.env.REACT_APP_SHARED_API_URL;
if (!sharedUrl) {
  throw new Error('Missing env variable REACT_APP_SHARED_API_URL');
}
const businessUrl = process.env.REACT_APP_BUSINESS_WEB_URL;
if (!businessUrl) {
  throw new Error('Missing env variable REACT_APP_BUSINESS_WEB_URL');
}

export default () => {
  const { isAuthenticated } = useAuthContext();
  const [products, setProducts] = useState([]);
  const { t } = useLocale();

  useEffect(() => {
    if (isAuthenticated) {
      const url = `${sharedUrl}/navigation/urlsforuser?businessWebBaseUrl=${businessUrl}`;
      apiRequest(url).then((data) => {
        if (data.error) return;

        let withText = data.map((entry) => {
          let label = t(`Navigator.DisplayText.${entry.navigationID}`) || entry.displayText;
          return {
            ...entry,
            label,
          };
        });
        setProducts(withText);
      });
    }
  }, [isAuthenticated, t]);
  return products;
};
