import { useEffect, useMemo, useReducer } from 'react';
import { sub } from 'date-fns';
import { useDebounce } from 'use-debounce';
import { api } from 'api';
import { sortBy } from 'utils/sorting';
import { makeQueryFromState } from 'helpers';

export function useOverviewState(monitors) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [debouncedSearch] = useDebounce(state.search, 1000);

  useEffect(() => {
    dispatch({ type: 'set_loading', payload: true });
    if (state.selectedMonitor) {
      const query = makeQueryFromState({ ...state, search: debouncedSearch });

      if (state.selectedMonitor === 'all') {
        api.getSearchData(query).then((data) => {
          !data.error && dispatch({ type: 'set_search_data', payload: data });
        });
        api.getTrendData(query).then((data) => {
          !data.error && dispatch({ type: 'set_trend_data', payload: data });
        });
        // api.getSearchData(query).then((data) => {
        //   !data.error && dispatch({ type: 'set_global_media', payload: data.matchingMedias });
        // });
      } else {
        // Use until social media is merged with other media
        const selMonitor = state.monitors.filter((m) => m.value === state.selectedMonitor);

        if (selMonitor && selMonitor[0].monitorTypeId === 11) {
          api.getMonitorData(query).then((data) => {
            !data.error && dispatch({ type: 'set_monitor_data', payload: data });
          });
        } else {
          api.getSearchData(query).then((data) => {
            !data.error && dispatch({ type: 'set_monitor_data', payload: data });
          });
          api.getTrendData(query).then((data) => {
            !data.error && dispatch({ type: 'set_trend_data', payload: data });
          });
        }
      }
    }
  }, [
    state.selectedMonitor,
    debouncedSearch,
    state.fromDate,
    state.toDate,
    state.filteredIdentifiers,
    state.filteredMedias,
    state.filteredAnalysis,
    state.filteredScore,
    state.start,
    state.count
  ]);
  //Global Media from search results -
  // useEffect(() => {
  //   dispatch({ type: 'set_loading', payload: true });
  //   if (state.selectedMonitor) {
  //     const query = makeQueryFromState({ ...state, search: state.search });
  //     api.getSearchData(query).then((data) => {
  //       !data.error && dispatch({ type: 'set_global_media', payload: data.matchingMedias });
  //     });
  //   }
  // }, [
  //   state.selectedMonitor,
  //   debouncedSearch,
  //   state.filteredIdentifiers,
  //   state.fromDate,
  //   state.toDate
  // ]);

  useEffect(() => {
    if (monitors) {
      dispatch({ type: 'set_monitors', payload: monitors });
    }
  }, [monitors]);

  useEffect(() => {
    api.getMediaList().then((res) => {
      if (!res.error) {
        dispatch({ type: 'set_global_media', payload: res });
      }
    });
  }, []);

  useEffect(() => {
    if (state.selectedMonitor && state.selectedMonitor !== 'all') {
      api.getMonitorInfo(state.selectedMonitor).then((data) => {
        !data.error && dispatch({ type: 'set_monitor_info', payload: data });
      });
    }
  }, [state.selectedMonitor]);

  const actions = useMemo(() => {
    return {
      onMonitorSelect: (payload) => dispatch({ type: 'select_monitor', payload }),
      onSearchChange: (payload) => dispatch({ type: 'set_search', payload }),
      onRegnoChange: (payload) => dispatch({ type: 'set_filtered_regnos', payload }),
      onMediaChange: (payload) => dispatch({ type: 'set_filtered_medias', payload }),
      onAnalysisChange: (payload) => dispatch({ type: 'set_filtered_analysis', payload }),
      onScoreChange: (payload) => dispatch({ type: 'set_filtered_score', payload }),
      onDateChange: (payload) => dispatch({ type: 'set_date', payload }),
      onStartChange: (payload) => dispatch({ type: 'set_start', payload }),
      onCountChange: (payload) => dispatch({ type: 'set_count', payload }),
      toggleFilteredIdentifier: (payload) =>
        dispatch({ type: 'toggle_filtered_identifier', payload }),
      toggleFilteredMedia: (payload) => dispatch({ type: 'toggle_filtered_media', payload }),
      toggleFilteredAnalysis: (payload) => dispatch({ type: 'toggle_filtered_analysis', payload }),
      toggleFilteredScore: (payload) => dispatch({ type: 'toggle_filtered_score', payload })
    };
  }, [dispatch]);

  return [state, actions];
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set_date': {
      return { ...state, fromDate: action.payload.from, toDate: action.payload.to };
    }
    case 'set_global_media': {
      const medias = action.payload.map((media) => ({
        display: media.name,
        value: media.mediaId
      }));
      // medias.unshift({ display: 'Allt', value: Number(-1) });
      const sortedMedias = sortBy(medias, 'display', 'asc');
      return { ...state, globalMediaList: sortedMedias };
    }
    case 'set_monitors': {
      const monitors = action.payload
        .map((mon) => ({
          value: String(mon.monitorId),
          display: mon.displayName,
          monitorTypeId: mon.monitorTypeId
        }))
        .sort((a, b) => a.display.localeCompare(b.display));

      const selectedMonitor = monitors.length > 0 ? monitors[0].value : 'all';
      monitors.unshift({ value: 'all', display: 'Allt safnið', monitorTypeId: null });

      return {
        ...state,
        monitors,
        selectedMonitor
      };
    }
    case 'set_search_data': {
      return {
        ...state,
        searchData: action.payload,
        loading: false,
        lastRefresh: new Date()
      };
    }
    case 'set_trend_data': {
      return {
        ...state,
        trendData: action.payload,
        loading: false,
        lastRefresh: new Date()
      };
    }
    case 'set_monitor_data': {
      // const scripts = action.payload.scripts.map((script) => {
      //   // let tags = [];
      //   // if (script.matches) {
      //   //   script.matches.forEach((mi) => {
      //   //     tags.push({
      //   //       ...mi,
      //   //       ...(script.matches && {
      //   //         identifiers: mi.regno ? mi.name : null
      //   //       }),
      //   //       tagType: mi.regno ? 'identifier' : 'query'
      //   //     });
      //   //   });
      //   // }

      //   // if (script.matchingQueries) {
      //   //   script.matchingQueries.forEach((mq) => {
      //   //     tags.push({
      //   //       ...mq,
      //   //       ...(script.scoreAndAnalysis && {
      //   //         scoreAndAnalysis: script.scoreAndAnalysis.find((x) => x.name === mq.name)
      //   //       }),
      //   //       tagType: 'query'
      //   //     });
      //   //   });
      //   // }

      //   // delete script.matchingIdentifiers;
      //   // delete script.matchingQueries;
      //   // delete script.scoreAndAnalysis;
      //   return {
      //     ...script,
      //     tags
      //   };
      // });
      return {
        ...state,
        monitorData: action.payload, //{ ...action.payload, scripts },
        loading: false,
        lastRefresh: new Date()
      };
    }
    case 'set_monitor_info': {
      let medias = [];
      if (action.payload.medias) {
        Object.keys(action.payload.medias).forEach((mediaTypeId) => {
          const items = action.payload.medias[mediaTypeId];
          items.forEach((item) => {
            medias.push({
              value: item.mediaId,
              display: item.name,
              mediaTypeId: Number(mediaTypeId)
            });
          });
        });
      }
      // medias.unshift({ value: Number(-1), display: 'Allt', mediaTypeId: null });
      const sortedMedias = sortBy(medias, 'display', 'asc');

      const idents = action.payload.identifiers
        ? action.payload.identifiers.map((ide) => ({
            value: ide.identifierId,
            display: ide.name
          }))
        : [];
      const sortedIdentifiers = idents.sort((a, b) => a.display.localeCompare(b.display));

      return {
        ...state,
        monitorInfo: { ...action.payload, medias: sortedMedias, identifiers: sortedIdentifiers }
      };
    }

    case 'select_monitor': {
      return {
        ...initialState,
        fromDate: state.fromDate,
        toDate: state.toDate,
        monitors: state.monitors,
        globalMediaList: state.globalMediaList,
        selectedMonitor: action.payload
      };
    }
    case 'clear_monitor_info': {
      return { ...state, monitorInfo: null };
    }
    case 'set_search': {
      return { ...state, search: action.payload };
    }
    case 'set_count': {
      return { ...state, count: action.payload };
    }
    case 'set_loading': {
      return { ...state, loading: action.payload };
    }
    case 'set_filtered_regnos': {
      return { ...state, filteredIdentifiers: action.payload };
    }
    case 'toggle_filtered_identifier': {
      const identifiers = Object.assign({}, state.filteredIdentifiers);
      identifiers[action.payload] = !identifiers[action.payload];
      return { ...state, filteredIdentifiers: identifiers };
    }
    case 'toggle_filtered_media': {
      const medias = Object.assign({}, state.filteredMedias);
      if(Object.values(medias).find(item => item === true)){
        Object.keys(medias).forEach(mediaItem => {
          medias[mediaItem] = false;
        })  
      }
      else{
        action.payload.forEach(item => {
          medias[item] = !medias[item];
        })
      }
      return { ...state, filteredMedias: medias };
    }
    case 'set_filtered_medias': {
      return { ...state, filteredMedias: action.payload };
    }
    case 'set_filtered_analysis': {
      return { ...state, filteredAnalysis: action.payload };
    }
    case 'toggle_filtered_analysis': {
      const analysis = Object.assign({}, state.filteredAnalysis);
      analysis[action.payload] = !analysis[action.payload];
      return { ...state, filteredAnalysis: analysis };
    }
    case 'set_filtered_score': {
      return { ...state, filteredScore: action.payload };
    }
    case 'toggle_filtered_score': {
      const score = Object.assign({}, state.filteredScore);
      score[action.payload] = !score[action.payload];
      return { ...state, filteredScore: score };
    }
    default:
      return state;
  }
};

const initialState = {
  monitors: null,
  monitorData: null,
  trendData: null,
  monitorInfo: null,
  selectedMonitor: null,
  fromDate: sub(new Date(), { months: 6 }),
  toDate: new Date(),
  search: '',
  loading: false,
  lastRefresh: null,
  filteredIdentifiers: {},
  filteredMedias: {},
  filteredAnalysis: {},
  filteredScore: {},
  searchData: null,
  globalMediaList: [],
  start: 0,
  count: 20
};

// function isValidDate() {
//   const validDate = sub(new Date('2014-08-30T03:24:00'), { months: 6 });
//   console.log('time', validDate.getTime());
//   console.log('valid?', isNaN(validDate.getTime()));
//   console.log('validDAte', validDate);
//   return validDate;
// }
