import React from 'react';

import './ShowInPrint.less';

class ShowInPrint extends React.Component {
  render() {
    let addDiv = true;
    const children = React.Children.map(this.props.children, child => {
      if (child.props) {
        addDiv = false;
        let cs = 'showOnlyInPrint';
        if (child.props.className) {
          cs = child.props.className + ' showOnlyInPrint';
        }
        return React.cloneElement(child, { className: cs });
      }
      return child;
    });

    if (addDiv) {
      return <div className="showOnlyInPrint">{children}</div>;
    }
    return children;
  }
}

export default ShowInPrint;
