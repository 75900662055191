import React from 'react';
import PropTypes from 'prop-types';
import SidebarProducts from './Sidebar/SidebarProductsV2';
import SidebarFooter from './Sidebar/SidebarFooter';
import useWindowSize from '../hooks/useWindowSize';
// const ciIcon = process.env.REACT_APP_STATIC_IMG_URL + '/logo/brand-ci-icon.png';
const ciLogoText = process.env.REACT_APP_STATIC_IMG_URL + '/logo/brand-ci-text.png';

const Sidebar = ({ isExpanded, toggleExpanded, products, showNested = false }) => {
  const { width } = useWindowSize();

  if (!products || products.length === 0) return null;
  if (width < 1100 && !isExpanded) return null;
  return (
    <div className="sidebar small no-print" onClick={width < 1100 ? toggleExpanded : undefined}>
      <div className="sidebar-container">
        <div className="sidebar-top text-right">
          <button onClick={() => toggleExpanded} className="close-button">
            <i className="icon-close2"></i>
          </button>
          <div className="brand">
            <a href="/" title="">
              <img className="logo-text" src={ciLogoText} alt="Creditinfo" />
            </a>
          </div>
        </div>
        <div className="sidebar-content">
          <SidebarProducts showNested={showNested} items={products} mobile={width < 1100} isOpen={isExpanded} />
        </div>
        <SidebarFooter toggle={toggleExpanded} isOpen={isExpanded} />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  isExpanded: PropTypes.bool,
  toggleExpanded: PropTypes.func,
  products: PropTypes.array,
  showNested: PropTypes.bool,
};

export default Sidebar;
