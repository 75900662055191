import { api } from 'api';

export const getUrlAndRedirect = async (monitorId, scriptId, newTab = true) => {
  const res = monitorId ? await api.getMonitorScriptUrl(monitorId, scriptId) : await api.getSearchScriptUrl(scriptId);
  if (!res.error) {
    let a = document.createElement('a');
    a.href = res.url;
    if (newTab) {
      a.setAttribute('target', '_blank');
    }
    a.click();
    return true;
  }
  return null;
};

export const fetchFile = async (monitorId, scriptId, name) => (monitorId ? api.getMonitorScriptFile(monitorId, scriptId, name) : api.getSearchScriptFile(scriptId, name));

export const makeQueryFromState = (state) => ({
  from: state.fromDate,
  to: state.toDate,
  monitorId: Number(state.selectedMonitor),
  searchString: state.search,
  identifierIds: Object.keys(state.filteredIdentifiers).filter((key) => state.filteredIdentifiers[key]),
  analysis: Object.keys(state.filteredAnalysis)
    .filter((key) => state.filteredAnalysis[key])
    .map(Number),
  mediaScores: Object.keys(state.filteredScore)
    .filter((key) => state.filteredScore[key])
    .map(Number),
  mediaIds: Object.keys(state.filteredMedias)
    .filter((key) => state.filteredMedias[key])
    .map(Number),
  start: Number(state.start),
  count: Number(state.count)
});

export const removeSpecialCharacters = (str, spaeChar = null) => {
  const special = ['á', 'æ', 'ð', 'é', 'í', 'ó', 'ö', 'þ', 'ú', 'ý'];
  const rep = ['a', 'ae', 'd', 'e', 'i', 'o', 'o', 't', 'u', 'y'];
  if (spaeChar) {
    special.push(' ');
    rep.push(spaeChar);
  }
  var purify = str;
  for (let i = 0, l = special.length; i < l; i++) {
    var sp = new RegExp(special[i], 'gi');
    purify = purify.replace(sp, rep[i]);
  }
  return purify;
};
