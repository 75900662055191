import 'utils/polyfills';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

import * as Sentry from '@sentry/react';

import LanguageProvider from 'jsx/locale/LanguageProviderV2';
import AuthProvider from 'jsx/auth/AuthProviderV2';
import ScrollToTop from 'jsx/layout/ScrollToTopV2';

import './main.less';
import { App } from 'components/App';

// Sentry.init({ dsn: 'https://c0f70fa84be64e6aaa6b55885e60557b@o418448.ingest.sentry.io/5330584' });

const history = createBrowserHistory();

if (process.env.REACT_APP_USE_ANALYTICS === 'yes') {
  ReactGA.initialize('UA-56354358-3', { debug: false, titleCase: false });
  history.listen((location) => ReactGA.pageview(location.pathname + location.search));
}

if (process.env.REACT_APP_SENTRY_LOGGING === 'yes') {
  Sentry.init({
    dsn: 'https://c0f70fa84be64e6aaa6b55885e60557b@o418448.ingest.sentry.io/5330584',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_GIT_COMMIT_HASH,
  });
}

const authConfig = {
  authUrl: process.env.REACT_APP_STS_URL,
  clientId: 'WebMediaMonitor',
  scope: 'openid profile webmediamonitor internalapi creditinfo sharedapi offline_access',
  secret: 'WebMediaMonitorClientAccessKey2019',
};

render(
  <StrictMode>
    <AuthProvider authConfig={authConfig} history={history}>
      <Router history={history}>
        <LanguageProvider>
          <ScrollToTop />
          <App />
        </LanguageProvider>
      </Router>
    </AuthProvider>
  </StrictMode>,
  document.getElementById('root')
);
