export default {
  'austurfrett.is': 'austurfrett',
  'dv.is': 'dv',
  'eyjan.is': 'eyjan',
  'feykir.is': 'feykir',
  Fréttablaðið: 'frettabladid',
  'Frettabladid.is': 'frettabladid',
  'hringbraut.is': 'hringbraut',
  'http://www.dv.is': 'dv',
  'http://www.ruv.is': 'ruv',
  'kjarninn.is': 'kjarninn',
  'kvennabladid.is': 'kvennabladid',
  'MBL Íþróttir og erlent': 'mbl',
  'mbl.is': 'mbl',
  Morgunblaðið: 'morgunbladid',
  'Rás 1 og 2': 'ras1',
  'ruv.is': 'ruv',
  Sjónvarpið: 'sjonvarpid',
  'skagafjordur.is': 'skagafjordur',
  'skessuhorn.is': 'skessuhorn',
  'Smartland.is': 'smartland',
  'stundin.is': 'stundin',
  Stundin: 'stundin',
  'vb.is': 'vb',
  Viðskiptablaðið: 'vb',
  'vikudagur.is': 'vikudagur',
  'visir.is': 'visir',
};
