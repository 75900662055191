import React, { memo } from 'react';

import {
  NewsArticle,
  NewsArticleContent,
  NewsArticleDownload,
  NewsArticleHeadline,
  NewsArticleMediaIcon,
  NewsArticles,
  NewsArticleText
} from 'components/News';

export const CollectionArticles = memo(({ articles }) => {
  return (
    <>
      <NewsArticles>
        {articles.map((entry) => (
          <NewsArticle key={entry.scriptId}>
            <NewsArticleMediaIcon mediaName={entry.mediaName} />
            <NewsArticleContent>
              <NewsArticleHeadline
                date={entry.scriptDate}
                mediaName={entry.mediaName}
                title={entry.title}
              />
              <NewsArticleText>{entry.abstract}</NewsArticleText>
              <NewsArticleDownload item={entry} />
            </NewsArticleContent>
          </NewsArticle>
        ))}
      </NewsArticles>
    </>
  );
});
