import React from 'react';
import useLocale from 'jsx/locale/useLocale';

const TOP_MENU = [
  {
    id: 0,
    text: 'Fjölmiðlar',
    label: 'Fjölmiðlar',
    url: '/',
    exact: false,
    showInNavigation: false,
    breadcrumb: 'Fjölmiðlar'
  },
  {
    id: 1,
    text: 'Stillingar',
    label: 'Stillingar',
    url: '/settings',
    exact: false,
    breadcrumb: 'Fjölmiðlar'
  }
];

export const PAGE_NAV_FINANCEINFO = {
  id: 50,
  productID: 50,
  text: 'Stillingar',
  label: 'Viðskiptaborð',
  url: '/financeInfo',
  exact: true,
  showInNavigation: false,
  breadcrumb: 'Viðskiptaborð'
};

export default function useTopProducts(hasProductAccess) {
  const { t, locale } = useLocale();
  return React.useMemo(() => {
    let menu = TOP_MENU;
    if (!hasProductAccess) menu = [TOP_MENU[0]];
    return menu.map((product) => ({
      ...product,
      label: t(product.text) || product.text
    }));
  }, [t, locale, hasProductAccess]);
}
