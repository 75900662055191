import React, { forwardRef, useState } from 'react';
import c from 'clsx';
import { Manager, Reference, Popper } from 'react-popper';
import { ArrowDownIcon } from 'icons/ArrowDownIcon';
import { ClickOutside } from 'jsx/layout/ClickOutside';
import { Checkbox } from './Checkbox';
import { DatePicker } from './DatePicker';
import './Select.less';
import Flex from 'jsx/layout/Flex';
import { formatDate } from 'utils/date';
import useLocale from 'jsx/locale/useLocale';

export const Select = React.memo((props) => {
  const {
    disabled,
    variant = 'select',
    items,
    getDisplayTitle,
    title: displayTitle,
    defaultValue = null,
    getItemDisplay,
    onChange,
    isSearch
  } = props;
  const [open, setOpen] = React.useState(false);

  const computeSelected = () => {
    if (typeof defaultValue === 'string' || typeof defaultValue === 'number') {
      return { [defaultValue]: true };
    }
    if (defaultValue === null) {
      if (variant === 'date-multi') return { from: '', to: '' };
    }
    return defaultValue || {};
  };
  const [selected, setSelected] = React.useState(computeSelected);

  React.useEffect(() => {
    if (defaultValue) {
      if (
        typeof defaultValue === 'object' &&
        Object.keys(defaultValue) === 0 &&
        Object.keys(selected) === 0
      )
        return;
      setSelected(computeSelected());
    }
  }, [defaultValue]);

  const containerRef = React.useRef();

  const onSelect = (val, field) => {
    if (disabled) return;

    if (variant === 'date-multi') {
      setSelected((old) => {
        const newObj = Object.assign({}, old);
        newObj[field] = val;
        onChange(newObj);
        return newObj;
      });
      return;
    }

    if (variant === 'select') {
      setOpen(false);
      setSelected({ [val]: true });
      onChange(val);
      return;
    }

    if (variant === 'multi' || variant === 'tag') {
      setSelected((old) => {
        const newVal = Object.assign({}, old);
        newVal[val] = !newVal[val];

        if (val === -1) {
          // select/clear all
          Object.values(items).forEach((item) => {
            newVal[item.value] = newVal[val];
          });
        }
        onChange(newVal);
        return newVal;
      });
      return;
    }
  };

  const title = React.useMemo(() => {
    let title = displayTitle || '';

    if (variant === 'date-multi' || variant === 'tag') return title;
    const selectedKeys = Object.keys(selected).filter((key) => Boolean(selected[key]));
    if (selectedKeys.length > 0) {
      title = selectedKeys
        .map((k) => {
          if(items.find((it) => String(it.value) === String(k)) != null){
            return items.find((it) => String(it.value) === String(k)).display;
          }
        })
        .join(', ');
      if (getDisplayTitle) {
        return getDisplayTitle(title);
      }
    }

    return title;
  }, [displayTitle, getDisplayTitle, selected, variant]);

  return variant === 'tag' ? (
    <RenderSelectVariants
      variant={variant}
      items={items}
      onSelect={onSelect}
      selected={selected}
      getItemDisplay={getItemDisplay}
      title={title}
    />
  ) : (
    <ClickOutside
      onOutsideClick={(e) => {
        if (disabled) return;
        if (open) {
          e.stopPropagation();
          e.preventDefault();
          setOpen(false);
        }
      }}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref}>
              <SelectContainer
                disabled={disabled}
                tabIndex={disabled ? -1 : 0}
                open={open}
                ref={containerRef}
                onClick={() => {
                  if (disabled) return;
                  setOpen((old) => !old);
                }}
              >
                <SelectText>{title}</SelectText>
                <Arrow open={open} />
              </SelectContainer>
            </div>
          )}
        </Reference>
        {open && (
          <Popper placement="bottom-start">
            {({ ref, style, placement, arrowProps }) => {
              const parentWidth = containerRef.current.getBoundingClientRect().width;
              return (
                <OptionsContainer
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  width={parentWidth}
                  allowOverflow={variant !== 'date-multi'}
                >
                  <RenderSelectVariants
                    variant={variant}
                    items={items}
                    onSelect={onSelect}
                    selected={selected}
                    title={title}
                    getItemDisplay={getItemDisplay}
                    isSearch={isSearch}
                  />
                  <div ref={arrowProps.ref} style={arrowProps.style} />
                </OptionsContainer>
              );
            }}
          </Popper>
        )}
      </Manager>
    </ClickOutside>
  );
});

const RenderSelectVariants = React.memo((props) => {
  const { variant, items, onSelect, selected, getItemDisplay, title, isSearch } = props;
  const { t } = useLocale();
  const searchPlaceHolder =
    title == 'Miðlar'
      ? t('searchByMediasPlaceHolder')
      : title == 'Afmarka við ...'
      ? t('searchByIdentifiersPlaceHolder')
      : '';
  const [search, setSearch] = useState('');
  var filterdItems =
    !items || search === ''
      ? items
      : items.filter((it) => {
          var matchSearch = new RegExp(search, 'i');
          if (String(it.display).search(matchSearch) !== -1) {
            return it;
          }
        });

  switch (variant) {
    case 'date-multi':
      return (
        <div>
          <div>
            <DateLabel>Frá:</DateLabel>
            <DatePicker
              value={selected.from}
              onChange={(val) => onSelect(val, 'from')}
              maxDate={selected.to}
            />
          </div>
          <div style={{ marginTop: '0.75rem' }}>
            <DateLabel>Til:</DateLabel>
            <DatePicker
              value={selected.to}
              onChange={(val) => onSelect(val, 'to')}
              minDate={selected.from}
            />
          </div>
        </div>
      );
    case 'select':
      return (
        <>
          {items.map((item) => (
            <Option
              active={Boolean(selected[item.value])}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect(item.value);
              }}
              key={item.value}
            >
              {item.display}
            </Option>
          ))}
        </>
      );
    case 'multi':
      return (
        <>
          {isSearch !== undefined && (
            <div className="form">
              <input
                type="text"
                placeholder={searchPlaceHolder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </div>
          )}
          {filterdItems.map((item) => {
            const checked = Boolean(selected[item.value]);
            const display = getItemDisplay ? getItemDisplay(item.display) : item.display;
            return (
              <Option
                active={checked}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelect(item.value);
                }}
                key={item.value}
              >
                <Checkbox checked={checked} label={display} />
              </Option>
            );
          })}
        </>
      );
    case 'tag':
      return items ? (
        <>
          {items.map((item) => {
            const checked = Boolean(selected[item.value]);
            const display = getItemDisplay ? getItemDisplay(item.display) : item.display;
            return checked ? (
              <button
                className="tag"
                title="Hreinsa val"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSelect(item.value);
                }}
                key={item.value}
              >
                <span style={{ display: Flex, fontSize: '0.7rem', color: '#999' }}>{title}</span>
                <span>{display}</span>
              </button>
            ) : null;
          })}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              color: '#333',
              fontSize: '1rem'
            }}
          >
            <span style={{ display: Flex, fontSize: '1rem', color: '#999' }}>{title}</span>
            <span>
              {selected.from ? formatDate(selected.from) : ''} -{' '}
              {selected.to ? formatDate(selected.to) : ''}
            </span>
          </div>
        </>
      );
    default:
      return null;
  }
});

const SelectText = (props) => <div className="__select-text" {...props} />;

const SelectContainer = forwardRef(({ disabled, open, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={c('__select-container', {
        disabled,
        open
      })}
      {...rest}
    />
  );
});

const DateLabel = (props) => <div {...props} className="__select-date-label" />;

const OptionsContainer = forwardRef(({ width, style, allowOverflow, ...rest }, ref) => (
  <div
    ref={ref}
    className={c('__select-options-container', { 'allow-overflow': allowOverflow })}
    style={{ ...style, minWidth: width }}
    {...rest}
  />
));

const Option = ({ active, ...rest }) => (
  <div className={c('__select-option', { active })} {...rest} />
);

const Arrow = ({ open, ...rest }) => {
  return (
    <ArrowDownIcon
      style={{
        flexShrink: 0,
        transition: 'transform 150ms linear',
        marginLeft: '0.75rem',
        zIndex: 0,
        ...(open && {
          transform: 'rotate(180deg)'
        })
      }}
      {...rest}
    />
  );
};
