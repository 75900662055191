import React, { useState } from 'react';
import Spinner from 'jsx/elements/spinner';
import { useOverviewState } from 'hooks/useOverviewState';
import { ExcelExportButton } from 'components/ExcelExportButton';
import { OverviewFilters } from 'components/OverviewFilters';
import { Collection } from 'components/Collection';
import { Monitor } from 'components/Monitor';
import MoreItemsArrow from 'jsx/elements/buttons/MoreItemsArrow';
import { useHistory } from 'react-router-dom';
import Button from 'jsx/elements/buttons/Button';
// import { RightSidebar } from 'jsx/layout';

export const Overview = ({ monitors, hasProductAccess }) => {
  const [state, actions] = useOverviewState(monitors);
  const [selectedTab, showSelectedTab] = useState('Graphs');
  const history = useHistory();
  const selectTab = (e) => {
    e.preventDefault();
    showSelectedTab(e.target.name);
  };
  const isSocialMedia = state.monitorInfo && state.monitorInfo.monitor.monitorTypeId === 11;

  return (
    <>
      <OverviewHeader state={state} />
      <OverviewFilters state={state} actions={actions} />
      {state.selectedMonitor !== 'all' && !isSocialMedia && (
        <div className="tabs-container simple blue">
          <ul className="tabs">
            <li>
              <a href="#" name="Graphs" className={selectedTab === 'Graphs' ? 'selected' : ''} onClick={(e) => selectTab(e)}>
                Greining
              </a>
            </li>
            <li>
              <a href="#" name="News" className={selectedTab === 'News' ? 'selected' : ''} onClick={(e) => selectTab(e)}>
                Niðurstöður
              </a>
            </li>
          </ul>
        </div>
      )}

      {state.loading && <Spinner center size="medium" />}

      <div style={{ ...(state.loading && loadingStyles) }}>
        {state.selectedMonitor === 'all' ? (
          <>
            {!hasProductAccess && <RequestButton onClick={() => history.push('/request-access')} />}
            <Collection key={state.lastRefresh} data={state.searchData} trend={state.trendData} count={state.count} searchString={state.search} showGraphs={selectedTab === 'Graphs'} />
            {state.searchData && <MoreNews state={state} actions={actions} totalCount={state.searchData.totalCount} />}
          </>
        ) : (
          <>
            <Monitor showGraphs={selectedTab === 'Graphs'} state={state} actions={actions} />
            {state.monitorData && selectedTab === 'News' && <MoreNews state={state} actions={actions} totalCount={state.monitorData.totalCount} />}
          </>
        )}
      </div>
    </>
  );
};

const loadingStyles = { opacity: 0.6, pointerEvents: 'none' };

const OverviewHeader = ({ state }) => {
  return (
    <div className="text-right">
      {/* <h2 className="text-gray-dark text-28">Fjölmiðlar</h2> */}
      <ExcelExportButton state={state} />
    </div>
  );
};

const MoreNews = ({ state, actions, totalCount }) => {
  return state.count <= totalCount ? (
    <div style={{ marginTop: 10 }}>
      <MoreItemsArrow text={'Birta fleiri greinar'} onClick={() => actions.onCountChange(state.count + 50)}></MoreItemsArrow>
    </div>
  ) : null;
};

const RequestButton = ({ onClick }) => {
  return (
    <div style={{ textAlign: 'right', marginTop: '15px' }}>
      <Button text={'Viltu frekari greiningar?'} onClick={onClick} color="red" />
    </div>
  );
};
