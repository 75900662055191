import React from 'react';
import { useParams, useHistory } from 'react-router';
import { fetchFile } from 'helpers';

export const MonitorScriptFile = () => {
  const { monitorID, scriptID } = useParams();
  const history = useHistory();

  React.useEffect(() => {
    fetchFile(monitorID, scriptID, monitorID + '-' + scriptID);
    history.push('/');
  }, [monitorID, scriptID]);

  return null;
};
