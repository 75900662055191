import React from "react";
import { MediaBarChart } from "components/MediaBarChart";

export const MonitorMediaTotal = ({ data, onBarClick }) => {
  return (
    <MediaBarChart
      data={data}
      title="Vöktuð leitarorð eftir helstu miðlum"
      onBarClick={onBarClick}
    />
  );
};
