import React from 'react';
import { format } from 'date-fns';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { MonthAxisTick } from 'components/MonthAxisTick';
import { keyLables } from 'constants/score';

const colors = ['#C2282A', '#C4C4C4', '#8A3886', '#417732'];
const keys = ['Neikvætt', 'Hlutlaust', 'Jafnað', 'Jákvætt'];
// const keyLables = { '-1': 'Neikvætt', '0': 'Hlutlaust', '100': 'Jafnað', '1': 'Jákvætt' };

export const MonitorAnalysisTrend = ({ data: userData }) => {
  const [ref, hasEnteredView] = useHasEnteredView();
  const data = React.useMemo(() => {
    return Object.keys(userData).map((key) => {
      const year = key.substr(0, 4);
      const month = key.substr(4, 2);
      const dt = new Date(Number(year), Number(month) - 1).toISOString();
      const items = userData[key];
      let res = { date: dt };
      items.forEach((it) => {
        res[keyLables[it.id]] = it.value;
      });
      return res;
    }, {});
  }, [userData]);

  if (data.length === 0) return null;

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <h3 className="text-20 text-gray-dark">Innihaldsgreining - þróun</h3>
      <ResponsiveContainer width="100%" height={280}>
        <LineChart data={hasEnteredView ? data : []} margin={{ top: 10, left: -10, right: 10 }}>
          <Tooltip labelFormatter={(val) => format(new Date(val), 'dd.MM.yyyy')} />
          <CartesianGrid vertical={false} />
          <XAxis
            height={60}
            axisLine={false}
            dataKey="date"
            tickLine={false}
            interval="preserveStartEnd"
            tick={<MonthAxisTick />}
          />
          <YAxis tickLine={false} axisLine={false} />
          <Legend />
          {keys.map((key, index) => (
            <Line key={key} type="linear" dataKey={key} stroke={colors[index]} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
