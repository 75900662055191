import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { history, getQueryParams, updateQueryParam } from '../routing/history';
import LanguageContext from './LanguageContext';
import { getCurrentCookieLanguage, setCookie } from 'jsx/locale/helpers';

const swapLangQueryParam = lang => {
  const searchParams = getQueryParams();
  if (searchParams.lang) {
    updateQueryParam('lang', lang);
  }
};

const storedLang = getCurrentCookieLanguage();

export default ({ locale = undefined, children, lang = storedLang }) => {
  const [showKey, setShowKey] = useState(false);
  const [language, setLanguage] = useState(lang);
  const [localeObj, setLocaleObj] = useState(locale);

  function updateLanguage(lang) {
    setLanguage(lang);
    setCookie(lang);
    swapLangQueryParam(lang);
  }

  function updateLocaleObj(lang, newLocaleObj) {
    if (locale !== undefined) {
      throw new Error(
        'LanguageProvider is being controlled via the locale prop. ' +
          'updateLocaleForLang should only be used if the component is uncontrolled.'
      );
    } else {
      setLocaleObj(old => ({
        ...old,
        [lang]: newLocaleObj
      }));
    }
  }

  useEffect(() => {
    if (history.location.search.includes('show_locale_string')) {
      setShowKey(true);
    }
  }, []);

  let currentLocale = localeObj;
  // Check if locale is controlled from the outside.
  if (locale !== undefined) {
    currentLocale = locale;
  }

  function t(str, ...args) {
    if (showKey) return str;
    if (!str) return '';

    if (!currentLocale || Object.keys(currentLocale).length === 0 || !currentLocale[lang]) {
      return '';
    }

    let s = currentLocale[language][str];
    if (args.length === 0) return s;
    if (s) {
      for (let i = 0; i < args.length; i++) {
        let index = '{' + i + '}';
        s = s.replace(index, args[i]);
      }
    }
    return s;
  }

  return (
    <LanguageContext.Provider
      value={{
        lang: language,
        t: t,
        setLanguage: updateLanguage,
        updateLocaleForLang: updateLocaleObj,
        locale: currentLocale,
        localeLoaded: currentLocale !== undefined
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export class BackwardsCompatLanguageProvider extends React.Component {
  static childContextTypes = {
    locale: PropTypes.object
  };

  getChildContext() {
    const { locale, lang } = this.props;
    return {
      locale: {
        ...locale[lang],
        currentLang: lang
      }
    };
  }

  render() {
    return this.props.children;
  }
}
export { default as useLocale } from './useLocale';
