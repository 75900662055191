import React from 'react';
import { format } from 'date-fns';
import {
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { MonthAxisTick } from 'components/MonthAxisTick';
import { scoreColors } from 'constants/colors';
import { scoreIdtoTitle } from 'constants/score';

export const MonitorScoreTrend = ({ data: userData }) => {
  const [ref, hasEnteredView] = useHasEnteredView();

  const data = React.useMemo(() => {
    return Object.keys(userData).map((key) => {
      const year = key.substr(0, 4);
      const month = key.substr(4, 2);
      const dt = new Date(Number(year), Number(month) - 1).toISOString();
      const items = userData[key];
      let res = { date: dt };
      items.forEach((it) => {
        res[it.id] = it.value;
      });
      return res;
    }, {});
  }, [userData]);

  if (data.length <= 1) return null;
  const sorted = data.sort((b, a) => b.date - a.date);

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <h3 className="text-20 text-gray-dark">Fréttaskor - þróun</h3>
      <ResponsiveContainer width="100%" height={280}>
        <LineChart data={hasEnteredView ? sorted : []} margin={{ top: 10, left: -10, right: 10 }}>
          <Tooltip
            formatter={(a, b, props) => [props.value, scoreIdtoTitle[props.dataKey]]}
            labelFormatter={(val) => format(new Date(val), 'dd.MM.yyyy')}
          />
          <CartesianGrid vertical={false} />
          <XAxis
            interval="preserveStartEnd"
            height={80}
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tick={<MonthAxisTick />}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Legend
            formatter={(value, entry, index) => (
              <span>
                {index + 1}
                <svg width="15" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    transform={`translate(4, -1)`}
                    d="M10 3.877l-3.455-.502L5 .245l-1.545 3.13L0 3.877l2.5 2.437-.59 3.441L5 8.131l3.09 1.624-.59-3.44L10 3.876z"
                    fill="currentColor"
                  />
                </svg>
                <br />
                {scoreIdtoTitle[index + 1]}
              </span>
            )}
          />
          <Line type="linear" dataKey="1" stroke={scoreColors[1]} />
          <Line type="linear" dataKey="2" stroke={scoreColors[2]} />
          <Line type="linear" dataKey="3" stroke={scoreColors[3]} />
          <Line type="linear" dataKey="4" stroke={scoreColors[4]} />
          <Line type="linear" dataKey="5" stroke={scoreColors[5]} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
