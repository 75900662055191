export const sortBy = (data, sortBy, sortType = 'asc', inPlace = false) => {
  let arr = inPlace ? data : data.slice();
  return arr.sort((a, b) => {
    const aval = typeof a[sortBy] === 'string' ? a[sortBy].toLowerCase() : a[sortBy];
    const bval = typeof b[sortBy] === 'string' ? b[sortBy].toLowerCase() : b[sortBy];
    if (bval < aval) {
      return sortType === 'asc' ? 1 : -1;
    }
    if (bval > aval) {
      return sortType === 'asc' ? -1 : 1;
    }
    return 0;
  });
};
