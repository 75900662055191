import { useEffect, useState } from 'react';
import { translationApi } from 'utils/api/shared';
import { useLocale } from 'jsx/locale/LanguageProviderV2';

export default (source) => {
  const [loadedIS, setLoadedIS] = useState(false);
  const [loadedEN, setLoadedEN] = useState(false);
  const { updateLocaleForLang } = useLocale();

  useEffect(() => {
    if (source) {
      translationApi.getTranslationsIS(source).then((t) => {
        updateLocaleForLang('is', t);
        setLoadedIS(true);
      });
      translationApi.getTranslationsEN(source).then((t) => {
        updateLocaleForLang('en', t);
        setLoadedEN(true);
      });
    }
  }, []);

  return loadedIS && loadedEN;
};
