import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserMenuOpenToggleCtx } from '../UserMenuV2';

export const UMItem = ({ link, iconCls, label, openNewTab, onClick, children }) => {
  const { toggleOpen } = useContext(UserMenuOpenToggleCtx);

  const clickHandler = () => {
    onClick && onClick();
    toggleOpen && toggleOpen();
  };
  if (!onClick && !link) {
    return <li className={iconCls}>{children}</li>;
  }

  if (!link || link.startsWith('http')) {
    return (
      <li onClick={clickHandler}>
        <a href={link} target={openNewTab ? '_blank' : '_self'}>
          <i className={iconCls} />
          <span className="half-spacing-left">{label}</span>
        </a>
      </li>
    );
  }

  return (
    <li onClick={clickHandler}>
      <Link to={link}>
        <i className={iconCls} />
        <span className="half-spacing-left">{label}</span>
      </Link>
    </li>
  );
};

UMItem.propTypes = {
  link: PropTypes.string,
  iconCls: PropTypes.string,
  label: PropTypes.string,
  openNewTab: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
export default UMItem;
