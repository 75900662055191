import React from 'react';

const Flex = props => {
  const stl = {
    display: 'flex',
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    flexDirection: props.direction,
    ...props.style
  };
  return (
    <div style={stl} onClick={props.onClick} className={props.className}>
      {props.children}
    </div>
  );
};

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  direction: 'row'
};

export default Flex;
