import React from 'react';
import {
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { scoreColors } from 'constants/colors';
import { scoreIdtoTitle } from 'constants/score';

export const MonitorScoreTotal = ({ data, onBarClick }) => {
  const [hovering, setHovering] = React.useState(-1);
  const [ref, hasEnteredView] = useHasEnteredView();

  // data.map((item) => (item.label = scoreIdtoTitle[item.id]));

  if (data.length === 0) return null;
  const sorted = data.sort((b, a) => b.id - a.id);
  return (
    <div ref={ref} style={{ width: '100%' }}>
      <h3 className="text-20 text-gray-dark">Fréttaskor - samtals</h3>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          data={hasEnteredView ? sorted : []}
          margin={{ top: 15, left: -10, right: 10, bottom: 20 }}
        >
          <Tooltip
            cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
            formatter={(_, __, { payload }) => [payload.value, 'Fjöldi']}
          />
          <CartesianGrid vertical={false} />
          <YAxis tickLine={false} axisLine={false} />
          <XAxis
            axisLine={false}
            dataKey="id"
            tickLine={false}
            interval={0}
            tick={<CustomizedAxisTick />}
          />
          <Bar dataKey="value" maxBarSize={80}>
            {data.map(({ id }, index) => (
              <Cell
                onClick={() => onBarClick(id)}
                onMouseEnter={() => setHovering(index)}
                onMouseLeave={() => setHovering(-1)}
                style={{
                  ...(hovering === index && {
                    cursor: 'pointer',
                    opacity: 0.7
                  })
                }}
                fill={scoreColors[id]}
                key={index}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x - 5},${y})`} className="text-12">
      <text x={0} y={0} dy={16} fill="#333" textAnchor="middle">
        {payload.value}
      </text>
      <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          transform={`translate(5, 5)`}
          d="M10 3.877l-3.455-.502L5 .245l-1.545 3.13L0 3.877l2.5 2.437-.59 3.441L5 8.131l3.09 1.624-.59-3.44L10 3.876z"
          fill="currentColor"
        />
      </svg>
      <text x={0} y={20} dy={16} fill="#333" textAnchor="middle">
        {scoreIdtoTitle[payload.value]}
      </text>
    </g>
  );
};
