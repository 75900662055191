import React from 'react';
import { useMinHeightForMainContent } from 'jsx/hooks';

import { PDFViewer } from './PDFDocument';

import useHasTouchScreen from 'jsx/hooks/useHasTouchScreen';

export const DOCViewer = ({ doc = false }) => {
  const mainHeight = useMinHeightForMainContent() - 250;
  const hasTouchScreen = useHasTouchScreen();
  const docMinHeight = { height: mainHeight > 500 ? mainHeight : 500 };

  if (!doc) return null;
  return <>{doc && <PDFViewer doc={doc} frameHeight={docMinHeight} mobile={hasTouchScreen} />}</>;
};
