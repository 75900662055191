import { useState } from 'react';

/**
 * Hook for making generic stateful on/off boolean toggles.
 */
export default (initialValue = false) => {
  const [isToggled, setToggled] = useState(initialValue);

  const toggleFn = () => {
    setToggled(old => !old);
  };

  return [isToggled, toggleFn];
};
