import Cookies from 'js-cookie';
import { getQueryParams } from 'jsx/routing/history';

const hostname = window.location.hostname;
const cookieDomain = hostname.indexOf('localhost') !== -1 ? 'localhost' : 'creditinfo.is';

export const setCookie = lang => {
  Cookies.set('userCulture', 'c=is-IS|uic=' + lang, {
    expires: 365,
    path: '/',
    domain: cookieDomain
  });
};

export const getCurrentCookieLanguage = () => {
  let storedLang = 'is';

  let langCookie = Cookies.get('userCulture', { domain: cookieDomain });

  const { lang } = getQueryParams();

  if (!lang && langCookie !== undefined) {
    let idx = langCookie.indexOf('uic=');
    if (idx !== -1) {
      return langCookie.substr(idx + 4, 2);
    } else {
      idx = langCookie.indexOf('uic%3D');
      if (idx !== -1) {
        return langCookie.substr(idx + 6, 2);
      }
    }
  } else {
    setCookie(lang || storedLang);
    return lang || storedLang;
  }
};
