import { useState, useEffect } from 'react';
/**
 * Hook that returns bool true or false if user has Touch Screen.
 */
export default function useHasTouchScreen() {
  let [hasTouchScreen, sethasTouchScreen] = useState(null);

  useEffect(() => {
    if ('maxTouchPoints' in navigator) {
      sethasTouchScreen(navigator.maxTouchPoints > 0);
    } else if ('msMaxTouchPoints' in navigator) {
      sethasTouchScreen(navigator.msMaxTouchPoints > 0);
    } else {
      var mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        sethasTouchScreen(!!mQ.matches);
      } else if ('orientation' in window) {
        sethasTouchScreen(true); // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        var UA = navigator.userAgent;
        sethasTouchScreen(
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
      }
    }
  }, []);

  return hasTouchScreen;
}
