import { fetchFile, removeSpecialCharacters } from 'helpers';
import Warning from 'jsx/elements/messages/Warning';
import React, { useState, useEffect } from 'react';

import { PDFReader } from 'reactjs-pdf-reader';

import { b64ToPdfFile } from 'utils/api/fetchPdfBinary';

export const PDFViewer = ({ doc = false, frameHeight, mobile = null }) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [isPDFDownloaded, setIsPDFDownloadedled] = useState(null);
  const [pFileName, setPFileName] = useState(null);

  useEffect(() => {
    if (!pFileName) {
      const spT = removeSpecialCharacters(doc.title, '_');
      const spN = removeSpecialCharacters(doc.mediaName, '_');
      setPFileName(`${spN}_${spT}.pdf`);
    }
  }, [doc]);

  useEffect(() => {
    if (mobile !== null) {
      if (doc && mobile && pFileName) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var byteCharacters = atob(doc.scriptData);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {
            type: 'application/pdf'
          });
          window.navigator.msSaveOrOpenBlob(blob, pFileName);
        } else {
          setPdfFile(b64ToPdfFile(doc.scriptData, pFileName, true));
          setIsPDFDownloadedled(true);
        }
      } else if (doc && pFileName) {
        setPdfFile(b64ToPdfFile(doc.scriptData, pFileName, false, true));
      }
    }
  }, [doc, mobile, pFileName]);

  const onClick = () => {
    b64ToPdfFile(doc.scriptData, pFileName, true);
  };

  if (isPDFDownloaded && pFileName) {
    return (
      <Warning
        messageType="info"
        messageHeader={'Skjali hefur verið hlaðið niður'}
        messageBody={`PDF skjalið, "${pFileName}", var vistað á tækið þitt `}
      />
    );
  }

  if (!pdfFile && mobile !== null) return null;
  return (
    <>
      <div style={{ overflow: 'auto' }}>
        {pdfFile && <PDFReader data={pdfFile} width={'1000'} />}
      </div>
      <div className="getFile text-right">
        <button
          className="button link icon icon-file-pdf"
          style={{ overflow: 'visible' }}
          onClick={onClick}
        >
          Sækja skjal
        </button>
      </div>
    </>
  );
};
