export function splitSsn(ssn) {
  if(ssn === undefined || ssn === null) return '';
  if(ssn.includes('-')) return ssn;
  return ssn.substr(0, 6) + '-' + ssn.substr(6);
}

export function splitCreditCardNumber(cc) {
  if (cc.length === 16) {
    return cc.match(/.{1,4}/g).join('-');
  }
  if (cc.length === 4) {
    return `****-****-****-${cc}`;
  }
}

export function formatPropertyFixedNumber(propertyFixedNumber){
  if(propertyFixedNumber === undefined || propertyFixedNumber === null) return '';
  return propertyFixedNumber.substr(0, 3) + '-' + propertyFixedNumber.substr(3);
}

export function joinList(list, separator, property) {
  if (list) {
    return list
      .map(l => {
        return l[property];
      })
      .join(separator);
  }
  return '';
}

export const verifyRegnoListString = regnos => {
  const allRegnos = regnos.replace(/[-]/g, '').split(/[\s|,]+/).filter(r => r.length > 0);
  const invalidRegnos = allRegnos.filter(r => r.length !== 10);

  return {
    allRegnos,
    invalidRegnos
  };
};
