import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'jsx/hooks/useTranslation';
import PropTypes from 'prop-types';

const PageNavigation = ({ products }) => {
  const { t, loaded } = useTranslation();

  if (!products || products.length === 0) return null;
  return (
    <div className="page-nav no-print">
      <ul className="main-nav">
        {products.map((item, index) => {
          if (!item.showInNavigation) return null;
          return (
            <li key={index}>
              <>
                {loaded && (
                  <NavLink exact={item.url === '/' || item.exact} activeClassName="selected" to={item.url}>
                    {item.productID
                      ? t(`product_name_${item.productID}${item.subtreeID ? '_' + item.subtreeID : ''}`)
                      : item.label}
                  </NavLink>
                )}
                {!loaded && (
                  <NavLink exact={item.url === '/' || item.exact} activeClassName="selected" to={item.url}>
                    <span style={{ height: '20px' }}>...</span>
                  </NavLink>
                )}
              </>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PageNavigation;

PageNavigation.propTypes = {
  products: PropTypes.array,
};
