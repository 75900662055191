export const getVisibleWidth = () => {
  return (
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0
  );
};

export const getVisibleHeight = () => {
  return (
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0
  );
};

export const getElementHeight = el => {
  if (!el) return 0;
  return el.clientHeight || el.offsetHeight || el.scrollHeight;
};

export const getElementWidth = el => {
  return el.clientWidth || el.offsetWidth || el.scrollWidth;
};

export const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};
