import React from 'react';
import PropTypes from 'prop-types';
import usePageTitle from 'jsx/hooks/usePageTitle';
import PageNavigation from './PageNavigation';
import BreadCrumbs from './Header/BreadCrumbs';
import Flex from './Flex';
import Section from 'jsx/elements/section/Section';

const PageHeader = ({
  products = [],
  breadCrumbs = [],
  breadCrumbsOptions = {},
  backbutton,
  showNav = true,
  fixedTitle,
  titleTag,
  subTitle,
  rightActions,
  children,
}) => {
  const [pageTitle] = usePageTitle(products);
  const title = fixedTitle || pageTitle;
  const sectionClass = showNav ? 'page-header' : 'page-header no-gutter-bottom';

  return (
    <>
      <Section className={sectionClass}>
        {breadCrumbs && <BreadCrumbs products={breadCrumbs} options={breadCrumbsOptions}></BreadCrumbs>}
        <div className="grid">
          <div className="col s10of12">
            {/* Leitarbox */}
            {children}
            <div className="header-back">
              {backbutton && backbutton}
              <div>
                <Flex>
                  <h1 className="page-title">{title}</h1>
                  {titleTag && titleTag}
                </Flex>
                {subTitle && subTitle}
              </div>
            </div>
          </div>
          <div className="col s2of12 text-right">
            <>{rightActions && rightActions}</>
          </div>
        </div>
        {showNav && <PageNavigation products={products} />}
      </Section>
    </>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  breadCrumbs: PropTypes.array,
  breadCrumbsOptions: PropTypes.object,
  products: PropTypes.array,
  children: PropTypes.node,
  showNav: PropTypes.bool,
  backbutton: PropTypes.node,
  fixedTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleTag: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightActions: PropTypes.node,
};
