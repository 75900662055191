export function parseDate(str) {
  if (str === '' || str === undefined || str === null) return str;
  if (str === '0001-01-01T00:00:00') return '-';
  let date = new Date(str);
  let dt = date.getDate();
  let mn = date.getMonth() + 1;
  let yr = date.getFullYear();
  if (dt.toString().length === 1) dt = '0' + dt;
  if (mn.toString().length === 1) mn = '0' + mn;
  return dt + '.' + mn + '.' + yr;
}

export function parseTime(str) {
  if (str === '' || str === undefined || str === null) return str;
  if (str === '0001-01-01T00:00:00') return '-';
  let date = new Date(str);
  let hr = date.getHours();
  let mn = date.getMinutes();
  if (hr.toString().length === 1) hr = '0' + hr;
  if (mn.toString().length === 1) mn = '0' + mn;
  return hr + ':' + mn;
}

export function formatDate(date) {
  let dt = date.getDate();
  let mt = date.getMonth() + 1;
  let yr = date.getFullYear();
  if (dt.toString().length === 1) dt = '0' + dt;
  if (mt.toString().length === 1) mt = '0' + mt;
  return dt + '.' + mt + '.' + yr;
}

export function formatTime(date) {
  let hr = date.getHours();
  let mn = date.getMinutes();
  if (hr.toString().length === 1) hr = '0' + hr;
  if (mn.toString().length === 1) mn = '0' + mn;
  return hr + ':' + mn;
}

export function stringToDate(str, splitOn) {
  if (!str || !splitOn) return undefined;
  var date = str.split(splitOn).reverse();
  return new Date(date.join('-'));
}
