import React from 'react';
import PropTypes from 'prop-types';
import Button from '../buttons/ButtonV2';

const ExcelExport = ({ onClick, loading, tooltip, disabled, noText }) => {
  return (
    <>
      <Button
        track={{ catetory: 'Excel export', action: 'Export to excel', label: 'General excel export button' }}
        type={'action'}
        tooltip={tooltip}
        onClick={onClick}
        loading={loading}
        className={'no-print'}
        disabled={disabled}
        iconClass="icon-file-excel"
        noText
      >
        {!noText && 'Excel'}
      </Button>
    </>
  );
};

ExcelExport.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  noText: PropTypes.bool,
};

export default ExcelExport;
