import React from 'react';
import facebook from 'icons/facebook.svg';
import twitter from 'icons/twitter.svg';
import mediaMap from 'constants/mediaMap';
import { allColors } from 'constants/colors';
import { removeSpecialCharacters } from 'helpers';

const LOGO_ROOT = process.env.REACT_APP_NEWS_LOGO_ROOT;
const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};
const MediaIcon = ({ mediaName }) => {
  const [error, setError] = React.useState(false);

  if (mediaName === 'Facebook') {
    return <img src={facebook} alt="facebook" width="32" height="32" />;
  }

  if (mediaName === 'Twitter') {
    return <img src={twitter} alt="twitter" width="32" height="32" />;
  }

  var name = mediaMap[mediaName];
  if (!name) {
    const imgName = mediaName && removeSpecialCharacters(mediaName);
    name = imgName.replace('.is', '');
  }

  if (!name || error) {
    const iconColor = allColors[0]; //getRandomInt(allColors.length)
    return (
      <div
        className="no-media-icon"
        style={{
          background: iconColor,
          color: '#fff',
          fontSize: '2rem',
          textAlign: 'center',
          height: '42px',
          width: '42px',
          lineHeight: '42px'
        }}
      >
        {mediaName[0]}
      </div>
      // <svg id="icon-newspaper" viewBox="0 0 32 32" width="32" height="32" fill="#999">
      //   <text x="5" y="15" fill="red" font-style="bold">
      //     M
      //   </text>
      //   <path
      //     fill="#999"
      //     d="M28 8v-4h-28v22c0 1.105 0.895 2 2 2h27c1.657 0 3-1.343 3-3v-17h-4zM26 26h-24v-20h24v20zM4 10h20v2h-20zM16 14h8v2h-8zM16 18h8v2h-8zM16 22h6v2h-6zM4 14h10v10h-10z"
      //   />
      // </svg>
    );
  }

  return (
    <img
      onError={error ? null : () => setError(true)}
      src={LOGO_ROOT + name + '.png'}
      alt={mediaName}
    />
  );
};

export const NewsArticleMediaIcon = ({ mediaName }) => {
  return (
    <div className="__news-article-media">
      <MediaIcon mediaName={mediaName} />
    </div>
  );
};
