import React from 'react';
import clsx from 'clsx';

const SidebarFooter = ({ toggle, isOpen }) => {
  return (
    <div className="sidebar-footer" onClick={toggle}>
      <button className={clsx('button clear sidebarBtn icon-arrow-left2 pull-right no-text', isOpen ? 'rotate0' : 'rotate180')}>{/* <i className="icon-arrow-left2" /> */}</button>
    </div>
  );
};

export default SidebarFooter;
