import React from 'react';
import PropTypes from 'prop-types';

const SIZES = {
  small: 30,
  medium: 50,
  large: 75,
  'x-large': 100
};

const Spinner = ({ size = 'medium', color, extraCls, extraStl, center, relCenter }) => {
  let container = `spinner-container ${size} ${extraCls}`;
  let inner = `spinner icon-spinner2 ${size} ${color}`;
  const centerStl = {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: SIZES[size],
    height: SIZES[size],
    zIndex: 100
  };
  if (relCenter) {
    centerStl.position = 'absolute';
  }

  return (
    <div style={center || relCenter ? centerStl : {}}>
      <div className={container} style={extraStl}>
        <span className={inner} />
      </div>
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['x-small', 'small', 'medium', 'large', 'x-large']),
  color: PropTypes.oneOf(['red', 'gray', 'blue']),
  extraCls: PropTypes.string,
  extraStl: PropTypes.object
};

Spinner.defaultProps = {
  color: 'red',
  extraCls: '',
  extraStl: {},
  center: false,
  relCenter: false
};

export default Spinner;
