import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Section = React.forwardRef(({ limit = true, padded = true, customPadding, className, children }, ref) => {
  return (
    <section className={clsx('section', className && className)} ref={ref}>
      <div
        className={clsx(limit && 'limit', padded && !customPadding && 'padded', customPadding && customPadding)}
        style={{ position: 'relative' }}
      >
        {children}
      </div>
    </section>
  );
});

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.array.isRequired, PropTypes.node]),
  limit: PropTypes.bool,
  padded: PropTypes.bool,
  className: PropTypes.string,
  customPadding: PropTypes.string,
};
Section.displayName = 'Section';

export default Section;
