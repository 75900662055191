import apiRequest from './apiRequest';

const SHARED_URL = process.env.REACT_APP_SHARED_API_URL ? process.env.REACT_APP_SHARED_API_URL.replace(/\/$/, '') : '';

if (!SHARED_URL) {
  throw new Error('Missing SHARED_URL when using translationsApi');
}

const TRANS_URL = `${SHARED_URL}/translation`;

const getTranslation = (sourceID, langID) => {
  return apiRequest(`${TRANS_URL}/getTranslationDocument/${sourceID}/${langID}`);
};

export const translationsApi = {
  getTranslations: (sourceID, langID) => {
    if (typeof langID === 'string') {
      const id = langID.startsWith('en') ? 2 : 1;
      return getTranslation(sourceID, id);
    }
    const useID = langID || 1;
    return getTranslation(sourceID, useID);
  },
  getTranslationsIS: (sourceID) => {
    return getTranslation(sourceID, 1);
  },
  getTranslationsEN: (sourceID) => {
    return getTranslation(sourceID, 2);
  },
};
