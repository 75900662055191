export const PAGE_NAV = [
  {
    id: 0,
    text: 'Fjölmiðlar',
    label: 'Fjölmiðlar',
    url: '/',
    exact: false,
    showInNavigation: true,
    breadcrumb: 'Fjölmiðlar'
  },
  {
    id: 1,
    text: 'Stillingar',
    label: 'Vöktun',
    url: '/settings',
    exact: false,
    showInNavigation: true,
    breadcrumb: 'Fjölmiðlar'
  }
];
