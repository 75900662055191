import React, { memo } from 'react';
import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { format } from 'date-fns';
import { is } from 'date-fns/locale';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { MonthAxisTick } from 'components/MonthAxisTick';

export const CollectionMediaTrend = memo(({ data: userData, searchString }) => {
  const [ref, hasEnteredView] = useHasEnteredView();
  const str = searchString || 'Allar greinar';
  const data = userData.map((entry) => {
    return {
      ...entry,
      date: new Date(entry.year, entry.month - 1).toISOString(),
      [str]: entry.value
    };
  });

  return (
    <div style={{ width: '100%' }} ref={ref}>
      <h3 className="text-20 text-gray-dark">Þróun umfjöllunar</h3>
      <ResponsiveContainer width="100%" height={280}>
        <LineChart data={hasEnteredView ? data : []} margin={{ top: 10, left: -10, right: 15 }}>
          <Tooltip labelFormatter={(val) => format(new Date(val), 'MMMM yyyy', { locale: is })} />
          <CartesianGrid vertical={false} />
          <XAxis
            height={60}
            axisLine={false}
            dataKey="date"
            tickLine={false}
            interval={data.length > 15 ? 'preserveStartEnd' : 0}
            tick={<MonthAxisTick />}
          />
          <YAxis tickLine={false} axisLine={false} dataKey={str} />
          <Legend />
          <Line type="linear" dataKey={str} stroke="#C40F11" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
});
