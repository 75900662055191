import React from "react";
import {
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { allColors } from "constants/colors";
import { useHasEnteredView } from "hooks/useHasEnteredView";
import { MonthAxisTick } from "components/MonthAxisTick";
import { format } from "date-fns";

export const MonitorIdentifierIntervalTrend = ({
  data: userData,
  onLineClick,
}) => {
  const [ref, hasEnteredView] = useHasEnteredView();
  const [hovering, setHovering] = React.useState("");

  const [data, names] = React.useMemo(() => {
    let names = {};
    const data = Object.keys(userData).map((key) => {
      const year = key.substr(0, 4);
      const month = key.substr(4, 2);
      const dt = new Date(Number(year), Number(month) - 1).toISOString();
      const items = userData[key];
      let res = { date: dt };
      items.forEach((it) => {
        names[it.label] = true;
        res[it.label] = it.value;
      });
      return res;
    }, {});
    return [data, names];
  }, [userData]);
  if (data.length <= 1) return null;

  const onMouseEnter = (name) => setHovering(name);
  const onMouseLeave = () => setHovering("");

  return (
    <div ref={ref} style={{ width: "100%" }}>
      <h3 className="text-20 text-gray-dark">
        Aðilar í vöktun – þróun á tíðni umfjöllunnar
      </h3>
      <ResponsiveContainer width="100%" height={320}>
        <LineChart
          data={hasEnteredView ? data : []}
          margin={{ top: 10, left: -10, right: 20 }}
          style={{ paddingTop: 20 }}
          className="cursor-pointer"
        >
          <Tooltip
            labelFormatter={(val) => format(new Date(val), "dd.MM.yyyy")}
          />
          <CartesianGrid vertical={false} />
          <XAxis
            interval={data.length > 15 ? "preserveStartEnd" : 0}
            height={60}
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tick={<MonthAxisTick />}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Legend
            verticalAlign="top"
            onMouseEnter={(o) => onMouseEnter(o.dataKey)}
            onMouseLeave={onMouseLeave}
            onClick={onLineClick}
          />
          {Object.keys(names).map((name, index) => {
            return (
              <Line
                id={name}
                key={index}
                type="linear"
                dataKey={name}
                stroke={allColors[index % allColors.length]}
                strokeWidth={hovering === name ? 3 : 1}
                onClick={onLineClick}
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
