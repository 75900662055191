import React from 'react';
import { useTranslation } from 'jsx/hooks/useTranslation';
import PropTypes from 'prop-types';
import Flex from 'jsx/layout/Flex';
import './noRecordsFound.less';
import Spinner from '../spinner';

const NoRecordsFound = ({ desc, loading }) => {
  const { t } = useTranslation(2009);
  return (
    <div className="box noRecordsFound relative">
      {loading && (
        <Flex className="gutter">
          <Spinner relCenter />
        </Flex>
      )}
      {!loading && (
        <Flex>
          <span>
            <i className="icon-no-data" />
          </span>
          <span className="noRecords-desc">{desc ? desc : t('misc_noRecordsFound')}</span>
        </Flex>
      )}
    </div>
  );
};

export default NoRecordsFound;
NoRecordsFound.propTypes = {
  loading: PropTypes.bool,
  desc: PropTypes.string,
};
