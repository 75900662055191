import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

export default (products) => {
  const location = useLocation();

  const [pageTitle, setTitle] = useState('');

  useEffect(() => {
    if (products) {
      getMatchTitle();
    }
  }, [location]);

  const getMatchTitle = () => {
    const current = location.pathname.toLowerCase();
    const ti = products.find((prod) => prod.url.toLowerCase() === current);
    if (ti) {
      setTitle(ti.label);
    }
  };

  return [pageTitle];
};
