import React from 'react';
import c from 'clsx';
import './Input.less';

export const Input = React.forwardRef((props, ref) => {
  const { onChange, onFocus, onBlur, disabled, ...rest } = props;
  const change = React.useCallback((e) => onChange && onChange(e.target.value), [onChange]);
  return (
    <input
      className={c('__input-text', { disabled })}
      disabled={disabled}
      type="text"
      onChange={change}
      ref={ref}
      onFocus={() => {
        onFocus && onFocus();
      }}
      onBlur={() => {
        onBlur && onBlur();
      }}
      {...rest}
    />
  );
});
