import React from 'react';
import { Tooltip } from 'jsx/elements/messages/TooltipV2';

export const NewsArticles = ({ children }) => {
  return (
    <>
      <Separator />
      <Tooltip id="article-tag-type" />
      {React.Children.map(children, (child) => (
        <>
          {child}
          <Separator />
        </>
      ))}
    </>
  );
};

const Separator = (props) => (
  <div
    style={{
      height: 1,
      width: '100%',
      backgroundColor: '#BFBFBF',
    }}
    {...props}
  />
);
