import { useEffect } from 'react';

import { useAuthContext } from './AuthProviderV2';

export default ({ history }) => {
  const { loginCallback, logOut, setUser } = useAuthContext();

  useEffect(() => {
    loginCallback(history.location.hash)
      .then(history.push)
      .then(setUser)
      .catch(ex => {
        console.log(ex);
        logOut();
      });
  }, []);

  return null;
};
