import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import UserMenuItem from './UserMenu/UserMenuItemV2';
import useWindowSize from '../hooks/useWindowSize';
/**
 * Use this context for the open/close toggle so the user
 * has more flexibility with the structure of the children.
 */
export const UserMenuOpenToggleCtx = createContext({});
// export const useUserMenuOpenToggleCtx = () => useContext(UserMenuOpenToggleCtx);

/**
 * There are two main ways to use the UserMenu component:
 * 1. Use 'items' array which will create UserMenuItem instances for each item.
 * 2. Use 'children', which should contain only UserMenuItem elements.
 */
const UserMenu = ({ open = false, children, toggleOpen, items = [] }) => {
  let cls = 'no-print settings-drop menu-dropdown transition';

  if (open) {
    cls += ' open';
  }

  return (
    <UserMenuOpenToggleCtx.Provider value={{ toggleOpen }}>
      <OverlayClickOutside toggle={toggleOpen} show={open} />
      <div className={cls} style={{ width: '180px', color: '#2d2d2d' }}>
        {items.length > 0 ? (
          <ul>
            {items.map((item, i) => (
              <UserMenuItem iconCls={item.iconCls} label={item.label} onClick={item.onClick} key={i} link={item.link} />
            ))}
          </ul>
        ) : (
          <ul>{children}</ul>
        )}
      </div>
    </UserMenuOpenToggleCtx.Provider>
  );
};

UserMenu.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  toggleOpen: PropTypes.func,
  items: PropTypes.array,
};

export default UserMenu;

const OverlayClickOutside = ({ show, toggle }) => {
  const { height } = useWindowSize();
  if (!show) return null;

  return (
    <div
      style={{
        position: 'fixed',
        height,
        width: '100%',
      }}
      onClick={toggle}
    />
  );
};

OverlayClickOutside.propTypes = {
  show: PropTypes.bool,
  toggle: PropTypes.func,
};
