import React from 'react';
import { useParams } from 'react-router';

import { api } from 'api';
import NoRecordsFound from 'jsx/elements/messages/NoRecordsFound';
import Warning from 'jsx/elements/messages/Warning';
import { DOCViewer } from './DOCViewer';
// import { MonitorScriptUrl } from './MonitorScriptUrl';
import Spinner from 'jsx/elements/spinner';
import Sanitizer from 'jsx/elements/sanitizer/Sanitizer';

export const PublicScript = () => {
  const { scriptID } = useParams();
  const [script, setScript] = React.useState(null);
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (scriptID) {
      const params = {
        encryptedParameter: scriptID
      };
      api.getPublicScript(params).then((data) => {
        if (data === null) {
          setScript('no-records');
        } else if (data.error) {
          setError(data.error);
          setScript('error');
        } else {
          if (data.scriptData !== null) {
            if (Number(data.scriptDataType) === 1) {
              setScript(data);
            } else {
              openExternaUrl(data.sourceUrl);
            }
          } else {
            openExternaUrl(data.sourceUrl);
          }
        }
      });
    }
  }, [scriptID]);

  if (script === null) return <Spinner size="medium" center />;

  if (error) {
    // console.debug(error);
    return (
      <Warning
        messageType="info"
        messageHeader={'Frétt finnst ekki'}
        messageBody={
          <Sanitizer
            html={`Ekki tókst að finna frétt, mögulega er frestur vefslóðar runnin út.  <br /><i>${error.error}</br>`}
          />
        }
      />
    );
  }
  if (script === 'no-records') return <NoRecordsFound />;

  if (script && script.scriptData === null) {
    return (
      <Warning
        messageType="info"
        messageHeader={'Frétt fannst'}
        messageBody={'Ekki tókst að birta frétt'}
      />
    );
  }

  return <>{script && <DOCViewer doc={script} />}</>;
};

const openExternaUrl = (url, newTab) => {
  if (url) {
    let a = document.createElement('a');
    a.href = url;
    if (newTab) {
      a.setAttribute('target', '_blank');
    }
    a.click();
  }
};
