import React from 'react';
import { StarIcon } from 'components/StarIcon';

export const NewsArticleTag = ({ name, tagType, mediaScore, analyseId }) => {
  return (
    <>
      <div
        data-for='article-tag-type'
        data-tip={tagType === 'identifier' ? 'Félag í vakt' : 'Stikkorðasafn í vakt'}
        className='__news-article-tag'
      >
        <div>{name}</div>
        {((mediaScore !== null && mediaScore !== undefined) || (analyseId !== null && analyseId !== undefined)) && (
          <div className='__news-article-score'>
            {analyseId !== null && analyseId !== undefined && (
              <div className={`__news-article-analysis analysis-tag${analyseId}`}>{analysisToText[analyseId]}</div>
            )}
            {mediaScore !== null &&
              mediaScore !== undefined &&
              Array.from({ length: 5 }).map((_, i) => (
                <StarIcon key={i} style={{ color: mediaScore > i ? '#666' : '#BFBFBF' }} />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

const analysisToText = {
  1: 'Jákvætt',
  '-1': 'Neikvætt',
  0: 'Hlutlaust',
  100: 'Jafnað',
};
