import React, { memo } from 'react';

import { useRoleAccess } from 'jsx/hooks';

import {
  NewsArticle,
  NewsArticleHeadline,
  NewsArticles,
  NewsArticleTags,
  NewsArticleText,
  NewsArticleMediaIcon,
  NewsArticleDownload,
  NewsArticleContent
} from 'components/News';
import {
  MonitorAnalysisTotal,
  MonitorAnalysisTrend,
  MonitorMediaTotal,
  MonitorIdentifierIntervalTrend,
  MonitorIdentifierTotal,
  MonitorScoreAndAnalysis,
  MonitorScoreTotal,
  MonitorScoreTrend
} from 'components/Monitor';

import './Monitor.less';

export const Monitor = ({ state, actions, showGraphs }) => {
  const hasAnalysis = useRoleAccess(805);
  const hasScore = useRoleAccess(807);

  const data = state.monitorData;
  const trendData = state.trendData;
  // const trendError = Boolean(Object.state.trendData.error);
  if (!data) return null;

  const isSocialMedia = state.monitorInfo && state.monitorInfo.monitor.monitorTypeId === 11;
  if (isSocialMedia) {
    return (
      <RenderNews
        data={data.scripts}
        monitorId={Number(state.selectedMonitor)}
        count={data.totalCount}
      />
    );
  }

  if (!showGraphs) {
    return (
      <RenderNews
        data={data.scripts}
        monitorId={Number(state.selectedMonitor)}
        totalCount={data.totalCount}
        count={Number(state.count)}
        state={state}
      />
    );
  }

  return (
    <>
      {trendData && trendData.identifierTrend && (
        <RenderTotalGraphs
          mediaTrend={trendData.mediaTrend}
          identifierTrend={trendData.identifierTrend}
          actions={actions}
          lastRefresh={state.lastRefresh}
        />
      )}

      {trendData && trendData.identifierIntervalTrend && (
        <RenderIntervalTrendGraph
          data={trendData.identifierIntervalTrend}
          lastRefresh={state.lastRefresh}
          actions={actions}
          monitorInfo={state.monitorInfo}
        />
      )}

      {trendData && hasAnalysis && (
        <RenderAnalysisGraphs
          actions={actions}
          analysisTrend={trendData.analysisTrend}
          analysisIntervalTrend={trendData.analysisIntervalTrend}
          lastRefresh={state.lastRefresh}
        />
      )}
      {trendData && hasScore && (
        <RenderScoreGraphs
          actions={actions}
          mediaScoreTrend={trendData.mediaScoreTrend}
          mediaScoreIntervalTrend={trendData.mediaScoreIntervalTrend}
          lastRefresh={state.lastRefresh}
        />
      )}

      {trendData && trendData.mediaScoreVsAnalysis && hasAnalysis && hasScore && (
        <RenderScoreAndAnalysis
          lastRefresh={state.lastRefresh}
          data={trendData.mediaScoreVsAnalysis}
        />
      )}
    </>
  );
};

const RenderTotalGraphs = React.memo((props) => {
  const { lastRefresh, identifierTrend, mediaTrend, actions } = props;
  if (
    (!identifierTrend || identifierTrend.length === 0) &&
    (!mediaTrend || Object.keys(mediaTrend).length === 0)
  )
    return null;

  return (
    <GraphSection>
      {identifierTrend && (
        <MonitorIdentifierTotal
          key={`reg-tot-${lastRefresh}`}
          data={identifierTrend}
          onBarClick={({ id }) => {
            actions.toggleFilteredIdentifier(id);
          }}
        />
      )}
      {identifierTrend && identifierTrend.length > 0 && <GraphSpacer />}
      {mediaTrend && (
        <MonitorMediaTotal
          data={mediaTrend}
          key={`med-tot-${lastRefresh}`}
          onBarClick={actions.toggleFilteredMedia}
        />
      )}
    </GraphSection>
  );
});

const RenderIntervalTrendGraph = React.memo((props) => {
  const { data, lastRefresh, actions, monitorInfo } = props;
  if (!monitorInfo || monitorInfo.identifiers.length === 0) return null;

  return (
    <GraphSection>
      <MonitorIdentifierIntervalTrend
        onLineClick={({ value: name }) => {
          const { value } = monitorInfo.identifiers.find((iden) => iden.display === name);
          actions.toggleFilteredIdentifier(value);
        }}
        data={data}
        key={`reg-mon-${lastRefresh}`}
      />
    </GraphSection>
  );
});

const RenderAnalysisGraphs = React.memo((props) => {
  const { analysisTrend, analysisIntervalTrend, actions, lastRefresh } = props;
  if (
    (!analysisTrend || analysisTrend.length === 0) &&
    (!analysisIntervalTrend || Object.keys(analysisIntervalTrend).length === 0)
  )
    return null;

  return (
    <GraphSection>
      {analysisTrend && (
        <MonitorAnalysisTotal
          onBarClick={actions.toggleFilteredAnalysis}
          key={`ana-tot-${lastRefresh}`}
          data={analysisTrend}
        />
      )}
      {analysisTrend && analysisTrend.length > 0 && <GraphSpacer />}
      {analysisIntervalTrend && (
        <MonitorAnalysisTrend key={`ana-evo-${lastRefresh}`} data={analysisIntervalTrend} />
      )}
    </GraphSection>
  );
});

const RenderScoreGraphs = React.memo((props) => {
  const { mediaScoreTrend, mediaScoreIntervalTrend, actions, lastRefresh } = props;
  if (
    (!mediaScoreTrend || mediaScoreTrend.length === 0) &&
    Object.keys(mediaScoreIntervalTrend).length === 0
  )
    return null;

  return (
    <GraphSection>
      {mediaScoreTrend && (
        <MonitorScoreTotal
          onBarClick={actions.toggleFilteredScore}
          data={mediaScoreTrend}
          key={`score-total-${lastRefresh}`}
        />
      )}
      {mediaScoreTrend && mediaScoreTrend.length > 0 && <GraphSpacer />}
      {mediaScoreIntervalTrend && (
        <MonitorScoreTrend key={`score-evo-${lastRefresh}`} data={mediaScoreIntervalTrend} />
      )}
    </GraphSection>
  );
});

const RenderScoreAndAnalysis = React.memo((props) => {
  const { lastRefresh, data } = props;
  if (!data || Object.keys(data).length === 0) return null;

  return (
    <GraphSection>
      <MonitorScoreAndAnalysis key={`sco-ana-${lastRefresh}`} data={data} />
    </GraphSection>
  );
});

const RenderNews = React.memo((props) => {
  const { data, monitorId, count, totalCount } = props;
  //Experimental stuff
  // let taged = data;
  // if (data) {
  //   console.log('letsTag');
  //   taged = data.map((entry) => {
  //     // let tagedAbstract = entry.abstract;
  //     entry.tags.map((tag) => {
  //       entry.abstract = entry.abstract.replace(tag.name, `<span>${tag.name}</span>`);
  //     });
  //     return entry;
  //   });
  // } else {
  //   taged = data;
  // }

  // console.log('data', taged);

  return (
    <>
      <h2 className="text-24 text-gray-dark gutter-top">
        Greinar úr vakt {count <= totalCount ? `${count} af` : ''} {totalCount}
      </h2>
      <NewsArticles>
        {data.map((entry) => (
          <NewsArticle key={entry.scriptId}>
            <NewsArticleMediaIcon mediaName={entry.mediaName} />
            <NewsArticleContent>
              <NewsArticleHeadline
                date={entry.scriptDate}
                mediaName={entry.mediaName}
                title={entry.title}
                likes={entry.likeCount}
                shares={entry.shareCount}
              />
              <NewsArticleText>{entry.abstract}</NewsArticleText>
              <NewsArticleTags tags={entry.matches} />
              <NewsArticleDownload item={entry} monitorId={monitorId} />
            </NewsArticleContent>
          </NewsArticle>
        ))}
      </NewsArticles>
    </>
  );
});

const GraphSection = memo(({ children }) => {
  return <div className="gutter-top graph-section">{children}</div>;
});

const GraphSpacer = () => <div style={{ width: 50 }} />;
