export const scoreTitleToId = {
  'Mjög lítið vægi': 1,
  'Lítið vægi': 2,
  'Nokkurt vægi': 3,
  'Mikið vægi': 4,
  'Mjög mikið vægi': 5
};

export const scoreIdtoTitle = {
  1: 'Mjög lítið vægi',
  2: 'Lítið vægi',
  3: 'Nokkurt vægi',
  4: 'Mikið vægi',
  5: 'Mjög mikið vægi'
};

export const keyLables = { '-1': 'Neikvætt', '0': 'Hlutlaust', '100': 'Jafnað', '1': 'Jákvætt' };
