import React from 'react';
import { NewsArticleTag } from './NewsArticleTag';

export const NewsArticleTags = ({ tags, ...rest }) => {
  if (!tags || tags.length === 0) return null;
  return (
    <div className="__news-article-tags" {...rest}>
      {tags.map(({ name, regno, mediaScore, analyseId }, index) => (
        <NewsArticleTag
          key={index}
          name={name}
          tagType={regno ? 'identifier' : 'query'}
          mediaScore={mediaScore}
          analyseId={analyseId}
        />
      ))}
    </div>
  );
};
