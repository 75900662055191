import React from 'react';
import PropTypes from 'prop-types';

const MoreItemsArrow = ({ onClick, text, lessText = '', less = false }) => {
  const arrowClass = less ? 'top' : 'bottom';
  return (
    <div
      className="display-limit-trigger text-center"
      onClick={onClick}
      style={{ display: 'button', cursor: 'pointer' }}
    >
      {text && !less && <div className="text-12">{text}</div>}
      {text && less && <div className="text-12">{lessText}</div>}
      <span className={`gray-arrow arrow--${arrowClass}`} />
    </div>
  );
};

MoreItemsArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
  lessText: PropTypes.string,
  less: PropTypes.bool,
};

export default MoreItemsArrow;
