import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../messages/TooltipV2';
import clsx from 'clsx';
import { gaEvent } from 'jsx/hooks/useGoogleAnalytics';

const Button = React.forwardRef(
  (
    { type = 'primary', iconClass, iconRight, onClick, loading, disabled, className, tooltip, small, children, track },
    ref
  ) => {
    let loadingContent,
      textContent,
      textStyles = {};
    if (loading) {
      loadingContent = (
        <span className="spinner-container">
          <i className="icon-spinner2" />
        </span>
      );
      if (!children) textStyles = { textIndent: '0', maxWidth: '50px' };
    }
    if (children) {
      textContent = <span style={{ visibility: loading ? 'hidden' : 'visible' }}>{children}</span>;
    }
    const id = getRandomNumbers();
    const buttonClick = () => {
      if (track) {
        gaEvent(track);
      }
      onClick();
    };

    return (
      <>
        {tooltip && <Tooltip id={`btnTip${id}`} />}
        <button
          data-for={`btnTip${id}`}
          data-tip={tooltip}
          data-delay-show={500}
          ref={ref}
          className={clsx(
            'button btn',
            type,
            className,
            iconRight && 'right-icon',
            iconClass && iconClass,
            !children && 'no-text',
            small && 'small',
            loading && 'loading disabled'
          )}
          style={textStyles}
          onClick={!disabled && !loading ? buttonClick : null}
          disabled={disabled || loading}
        >
          {loadingContent}
          {textContent}
        </button>
      </>
    );
  }
);

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'action', 'link', 'green', 'blue']),
  iconClass: PropTypes.string,
  iconRight: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  track: PropTypes.object,
};
Button.displayName = 'Button';
export default Button;

const getRandomNumbers = () => {
  var array = new Uint16Array(1000);
  if (window.hasOwnProperty('crypto') && typeof window.crypto.getRandomValues === 'function') {
    window.crypto.getRandomValues(array);
  } else {
    //no support for crypto, get crappy random numbers
    for (var i = 0; i < 1000; i++) {
      array[i] = Math.floor(Math.random() * 100000) + 1;
    }
  }
  return array[0];
};
