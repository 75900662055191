import React, { useState, useEffect } from 'react';
import { splitSsn } from 'utils/strings';
import NoRecordsFound from 'jsx/elements/messages/NoRecordsFound';
import { MonitorSelect } from 'components/MonitorSelect';
import { api } from 'api';
import './MonitorSettings.less';

export const MonitorSettings = () => {
  const [state, setState] = useState({
    loading: true,
    selected: null,
    monitors: [],
    monitorInfo: null
  });

  useEffect(() => {
    api.getMonitors().then((data) => {
      setState({
        selected: data.length > 0 ? data[0] : null,
        monitors: data,
        monitorInfo: null,
        loading: false
      });
    });
  }, []);

  useEffect(() => {
    if (state.selected) {
      api.getMonitorInfo(state.selected.monitorId).then((data) => setState((old) => ({ ...old, monitorInfo: data })));
    }
  }, [state.selected]);

  const select = (item) => setState((old) => ({ ...old, selected: item }));

  return (
    <div>
      {/* <div className="text-gray-dark text-28">Vaktin mín</div> */}
      {!state.loading && state.monitors.length === 0 && (
        <div className="gutter-top">
          <NoRecordsFound />
        </div>
      )}
      <div className="gutter-top">
        <MonitorSelect monitors={state.monitors} selected={state.selected} onSelect={select} />
      </div>
      <MonitorInfo info={state.monitorInfo} />
    </div>
  );
};

const MonitorInfo = ({ info }) => {
  if (!info) return null;
  const { identifiers, queries } = info;

  return (
    <div>
      <div className="text-16 gutter-top half-spacing-bottom">
        <span>Aðilar sem eru vaktaðir</span>
        {/*
          Add questionmark icon when we know what we want with it
        <span className="half-spacing-left icon icon-question text-20" />
        */}
      </div>
      {identifiers.length === 0 && <NoRecordsFound />}
      {identifiers.length > 0 && (
        <>
          <div className="monitor-settings-list">
            {identifiers.map(({ name, regno }) => (
              <div key={regno} className="monitor-settings-item text-14">
                <strong>{name}</strong>
                <span className="half-spacing-left">{splitSsn(regno)}</span>
              </div>
            ))}
          </div>
        </>
      )}

      <div className="text-16 gutter-top half-spacing-bottom">
        <span>Leitarorð sem eru vöktuð</span>
        {/*
          Add questionmark icon when we know what we want with it
        <span className="half-spacing-left icon icon-question text-20" />
        */}
      </div>
      {queries.length === 0 && <NoRecordsFound />}
      {queries.length > 0 && (
        <>
          <div className="monitor-settings-list">
            {queries.map((item) => (
              <Query key={item.name} item={item} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const Query = ({ item }) => {
  var res = (
    <div key={item.name} className="monitor-settings-item-words text-14">
      <div className="monitor-item-name">
        <strong>{item.name}</strong>
      </div>
      {item.optionalWords && item.optionalWords.length > 0 && (
        <div className="monitor-settings-keywords ">
          <div>Leitarorð sem mega koma fyrir</div>
          <div className="keywords-optional">
            {item.optionalWords.map((word) => {
              return (
                <div className="__news-article-tag" key={word}>
                  <div>{word}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {item.notAllowedWords && item.notAllowedWords.length > 0 && (
        <div className="monitor-settings-keywords ">
          <div>Leitarorð sem mega ekki koma fyrir</div>
          <div className="keywords-optional">
            {item.notAllowedWords.map((word) => {
              return (
                <div className="__news-article-tag" key={word}>
                  <div>{word}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {item.requiredWords && item.requiredWords.length > 0 && (
        <div className="monitor-settings-keywords ">
          <div>leitarorð sem verða að koma fyrir</div>
          <div className="keywords-optional">
            {item.requiredWords.map((word) => {
              return (
                <div className="__news-article-tag" key={word}>
                  <div>{word}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
  return res;
};
