import React from 'react';

export const StarIcon = props => {
  return (
    <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 3.877l-3.455-.502L5 .245l-1.545 3.13L0 3.877l2.5 2.437-.59 3.441L5 8.131l3.09 1.624-.59-3.44L10 3.876z"
        fill="currentColor"
      />
    </svg>
  );
};
