import { useRef, useState, useCallback, useEffect } from 'react';
import throttle from 'lodash-es/throttle';
import { getVisibleHeight } from 'utils/browser';

export const useHasEnteredView = () => {
  const [hasEntered, setHasEntered] = useState(false);
  const containerRef = useRef();

  const checkContained = useCallback(() => {
    if (!containerRef.current) return;
    const rect = containerRef.current.getBoundingClientRect();
    const scrollPos = window.scrollY;
    if (getVisibleHeight() + scrollPos > rect.top) {
      setHasEntered(true);
    }
  }, []);

  useEffect(() => {
    const throttled = throttle(checkContained, 100);
    if (!hasEntered) {
      window.addEventListener('scroll', throttled);
      window.addEventListener('resize', throttled);
    }
    return () => {
      window.removeEventListener('scroll', throttled);
      window.removeEventListener('resize', throttled);
    };
  }, [hasEntered]);

  useEffect(() => {
    checkContained();
  }, [checkContained]);

  return [containerRef, hasEntered];
};
