import apiRequest from 'utils/api/apiRequest';
import FileSaver from 'file-saver';

const BASE = process.env.REACT_APP_MEDIAMONITOR_API_URL;
const MONITOR_URL = `${BASE}/monitor`;
const SEARCH_URL = `${BASE}/search`;
const COMMS_URL = `${BASE}/communication`;
const SCRIPT_URL = `${BASE}/script`;

// const withFakeDates = (data) => ({
//   ...data,
//   from: '2016-11-01T00:00:00.000Z',
//   to: '2020-12-31T23:59:59.999Z',
// });

export const api = {
  getMonitors: () => apiRequest(`${MONITOR_URL}/monitors`),
  getMonitorInfo: (id) => apiRequest(`${MONITOR_URL}/monitorinfo?monitorId=${id}`),
  getMonitorTeaser: () => apiRequest(`${MONITOR_URL}/monitorteaser`),
  getMonitorDataExcel: (data) => {
    return apiRequest(`${MONITOR_URL}/monitordataexcel`, {
      method: 'post',
      data,
      responseType: 'blob'
    });
  },
  getMonitorData: (data) => {
    return apiRequest(`${MONITOR_URL}/monitorsearch`, { method: 'post', data });
  },
  getTrendData: (data) => {
    return apiRequest(`${SEARCH_URL}/trend`, { method: 'post', data });
  },
  requestAccess: (data) => apiRequest(`${COMMS_URL}/requestaccess`, { method: 'post', data }),
  getMonitorScriptUrl: (monitorId, scriptId) => {
    return apiRequest(
      `${MONITOR_URL}/monitorscripturl?monitorId=${monitorId}&scriptId=${scriptId}`
    );
  },
  getMonitorScriptFile: async (monitorId, scriptId, filename) => {
    const res = await apiRequest(
      `${MONITOR_URL}/monitorscriptfile?monitorId=${monitorId}&scriptId=${scriptId}`,
      { responseType: 'blob' }
    );
    if (res.error) return res;
    try {
      FileSaver.saveAs(res, filename + '.pdf');
      return true;
    } catch (e) {
      console.log('Failed to create pdf', e);
      return false;
    }
  },
  // getPublicScript: () => apiRequest('/dummyPDF.json'),
  getPublicScript: (data) => {
    return apiRequest(`${SCRIPT_URL}/public`, { method: 'post', data });
  },

  getSearchData: (data) => {
    return apiRequest(`${SEARCH_URL}/search`, { method: 'post', data });
  },
  getSearchTrend: (data) => {
    return apiRequest(`${SEARCH_URL}/trend`, { method: 'post', data });
  },
  getSearchExcel: (data) => {
    return apiRequest(`${SEARCH_URL}/searchexcel`, {
      method: 'post',
      data,
      responseType: 'blob'
    });
  },
  getSearchScriptUrl: (scriptId) => apiRequest(`${SEARCH_URL}/scripturl?scriptId=${scriptId}`),
  getSearchScriptFile: async (scriptId, filename) => {
    const res = await apiRequest(`${SEARCH_URL}/scriptfile?scriptId=${scriptId}`, {
      responseType: 'blob'
    });
    try {
      FileSaver.saveAs(res, filename);
      return true;
    } catch (e) {
      console.log('Failed to create pdf', e);
      return false;
    }
  },
  getMediaList: () => apiRequest(`${MONITOR_URL}/medialist`)
};

if (process.env.NODE_ENV === 'development') {
  window.api = api;
}
