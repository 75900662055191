import React, { useState } from 'react';
import { ClickOutside } from 'jsx/layout/ClickOutside';
import SectionTitle from 'jsx/elements/section/SectionTitle';

export const MonitorSelect = ({ selected, monitors, onSelect }) => {
  const [open, setOpen] = useState(false);

  const handleClickOutside = () => open && setOpen(false);
  const toggle = () => setOpen((old) => !old);

  const toggleAndClick = (item) => {
    onSelect(item);
    toggle();
  };

  if (!selected) {
    return null;
  }

  let dropdownCls = 'custom-dropdown';

  if (open) dropdownCls += ' open';
  if (monitors.length === 1) {
    return <SectionTitle name={selected.displayName} />;
  }

  return (
    <ClickOutside onOutsideClick={handleClickOutside}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className="gutter-bottom">
        <div className={dropdownCls}>
          <span
            className="button clear custom-dropdown-trigger--large text-normal text-20"
            onClick={toggle}
          >
            {selected.displayName}
          </span>
          <div
            className="custom-dropdown-container no-print"
            style={{ top: '44px' }}
            onBlur={handleClickOutside}
          >
            <div>
              {monitors.map((item) => (
                <div key={item.monitorId} style={{ padding: '4px 0' }}>
                  <span
                    onClick={() => toggleAndClick(item)}
                    className="text-blue text-16 cursor-pointer"
                  >
                    {item.displayName}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ClickOutside>
  );
};
