import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ClearDoubleArrow = ({ url, text, left, newTab, onClick, className, arrowSide }) => {
  let classes = `button clear ${className}`;
  if (left) classes += ' icon-double-arrow-left';
  else classes += ' icon-double-arrow-right';
  if (arrowSide === 'right') {
    classes += ' right-icon';
  }

  if (newTab)
    return (
      <a className={classes} onClick={onClick} target="_blank" href={url} rel="noopener noreferrer">
        {text}
      </a>
    );
  return (
    <Link className={classes} onClick={onClick} to={url}>
      {text}
    </Link>
  );
};

ClearDoubleArrow.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  left: PropTypes.bool,
  newTab: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

ClearDoubleArrow.defaultProps = {
  url: '',
  text: '',
  left: false,
  newTab: false,
  className: '',
  arrowSide: 'right'
};

export default ClearDoubleArrow;
