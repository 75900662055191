import { useEffect } from 'react';

/**
 * Hook that removes the initial spinner when a certain condition is met.
 * Default just removes as soon as the hook is run.
 * @param {boolean} condition Remove init spinner on truthy condition
 */
export default function useRemoveInitApp(condition = true) {
  useEffect(() => {
    if (condition) {
      document.getElementsByTagName('html')[0].classList.remove('init-app');
    }
  }, [condition]);
}
