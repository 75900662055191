import { apiCall } from './apiCall';
import apiRequest from './apiRequest';

const BUSINESS_URL = process.env.REACT_APP_BUSINESS_WEB_URL
  ? process.env.REACT_APP_BUSINESS_WEB_URL.replace(/\/$/, '')
  : '';
const SHARED_URL = process.env.REACT_APP_SHARED_API_URL ? process.env.REACT_APP_SHARED_API_URL.replace(/\/$/, '') : '';

const TRANS_URL = `${SHARED_URL}/translation`;
const NAV_URL = `${SHARED_URL}/navigation`;
const SUPERVISOR_URL = `${SHARED_URL}/supervisor`;
const FORM_URL = `${SHARED_URL}/form`;

class TranslationAPI {
  getTranslationsIS = async (sourceId) => {
    const [common, trans] = await Promise.all([
      apiRequest(`${TRANS_URL}/getTranslationDocument/2009/1`),
      apiRequest(`${TRANS_URL}/getTranslationDocument/${sourceId}/1`),
    ]);
    return { ...common, ...trans };
  };

  getTranslationsEN = async (sourceId) => {
    const [common, trans] = await Promise.all([
      apiRequest(`${TRANS_URL}/getTranslationDocument/2009/2`),
      apiRequest(`${TRANS_URL}/getTranslationDocument/${sourceId}/2`),
    ]);
    return { ...common, ...trans };
  };
}

class NavigationAPI {
  getSettingsUrls = async () => {
    return await apiRequest(`${NAV_URL}/settingsurls?businessWebBaseUrl=${BUSINESS_URL}`);
  };
}

class SupervisorAPI {
  getAnnouncement = async (id) => {
    return await apiRequest(`${SUPERVISOR_URL}/announcements?id=${id}`);
  };
}

class FormAPI {
  getFormByID = (formId, companyRegno) => apiCall(`${FORM_URL}/${formId}/${companyRegno}`);

  getFormByGuid = (formGuid) => apiRequest(`${FORM_URL}/${formGuid}`);

  saveFormItems = async (data) => {
    const options = { method: 'post', data };
    return await apiRequest(`${FORM_URL}/save`, options);
  };

  getFormItemAttachment = (formGuid, attachmentID) => apiRequest(`${FORM_URL}/attachment/${formGuid}/${attachmentID}`);

  insertFormItemAttachment = async (data) => {
    const options = { method: 'post', data };
    return await apiRequest(`${FORM_URL}/attachment/upload`, options);
  };

  deleteFormItemAttachment = async (formGuid, attachmentID) => {
    const options = { method: 'delete' };
    return await apiRequest(`${FORM_URL}/attachment/${formGuid}/delete/${attachmentID}`, options);
  };
}

export const translationApi = new TranslationAPI();
export const navigationApi = new NavigationAPI();
export const supervisorApi = new SupervisorAPI();
export const formApi = new FormAPI();
