import React from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';

const Sanitizer = ({ html, config }) => {
  const purified = dompurify.sanitize(html, config);
  const sanitized = purified ? (
    <span className="sanitized" dangerouslySetInnerHTML={{ __html: purified }} />
  ) : (
    <span>{''}</span>
  );

  return sanitized;
};

Sanitizer.propTypes = {
  html: PropTypes.string,
  config: PropTypes.object
};
Sanitizer.default = {
  config: {}
};

export default Sanitizer;
