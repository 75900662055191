import React from 'react';
import PropTypes from 'prop-types';

const Trash = ({ color, onClick, disabled, ...rest }) => {
  let cls = `icon-remove2 text-${color}`;
  if (disabled) {
    cls += ' disabled';
  } else {
    cls += ' cursor-pointer';
  }
  return <span {...rest} onClick={disabled ? null : onClick} className={cls} />;
};

Trash.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

Trash.defaultProps = {
  color: 'blue',
  disabled: false
};

export default Trash;
