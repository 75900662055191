import React from 'react';
import {
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip
} from 'recharts';
import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { analysisColors } from 'constants/colors';
import { keyLables } from 'constants/score';

export const MonitorAnalysisTotal = ({ data: userData, onBarClick }) => {
  const [hovering, setHovering] = React.useState(-1);
  const [ref, hasEnteredView] = useHasEnteredView();
  if (userData.length === 0) return null;
  // console.log('MonitorAnalysisTotal', userData);
  const data = userData.map((it) => ({
    ...it,
    label: keyLables[it.id]
  }));
  return (
    <div style={{ width: '100%' }} ref={ref}>
      <h3 className="text-20 text-gray-dark">Innihaldsgreining - samtals</h3>
      <ResponsiveContainer width="100%" height={250}>
        <BarChart data={hasEnteredView ? data : []} margin={{ top: 15, left: -10, right: 10 }}>
          <Tooltip
            cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
            formatter={(_, __, { payload }) => [payload.value, 'Fjöldi']}
          />
          <CartesianGrid vertical={false} />
          <XAxis dataKey="label" tickLine={false} axisLine={false} tick={<CustomizedAxisTick />} />
          <YAxis axisLine={false} tickLine={false} />
          <Bar dataKey="value" barSize={80}>
            {data.map(({ id }, index) => (
              <Cell
                onClick={() => onBarClick(data[index].id)}
                onMouseEnter={() => setHovering(index)}
                onMouseLeave={() => setHovering(-1)}
                style={{
                  ...(hovering === index && {
                    cursor: 'pointer',
                    opacity: 0.7
                  })
                }}
                fill={analysisColors[id]}
                key={index}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`} className="text-12">
      <text x={0} y={0} dy={16} fill="#333" textAnchor="middle">
        {payload.value}
      </text>
    </g>
  );
};
