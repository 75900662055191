import React from 'react';
// import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { useAuthContext, getLocationData } from 'jsx/auth/AuthProviderV2';
// import RestrictDisclaimer from 'pages/RestrictDisclaimer';
// import PasswordSettings from 'pages/Settings/PasswordSettings';
import useRemoveInitApp from 'jsx/hooks/useRemoveInitApp';

export const ProtectedRoute = (props) => {
  const { isAuthenticated, signIn } = useAuthContext();
  // const history = useHistory();
  // const { unreviewed, loadedUnreviewedDisclaimers } = useSelector((state) => state.settings);
  // const changePass = useSelector((state) => state.customer.hasToChangePassword);
  useRemoveInitApp(isAuthenticated);

  if (!isAuthenticated) {
    signIn(getLocationData());
    return null;
  }

  // if (!loadedUnreviewedDisclaimers) {
  //   return null;
  // }

  // if (unreviewed.pending) {
  //   return <RestrictDisclaimer abort={logOut} history={history} />;
  // }

  // if (changePass) {
  //   return (
  //     <PasswordSettings staticHeader="restrictPassword_staticHeader" restrict history={history} />
  //   );
  // }

  return <Route {...props} />;
};
