import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

export const ClickOutside = ({ onOutsideClick, children, ...rest }) => {
  const containerRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (!containerRef.current.contains(event.target) && onOutsideClick) {
        onOutsideClick(event);
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  }, [onOutsideClick]);

  return (
    <div ref={containerRef} {...rest}>
      {children}
    </div>
  );
};

ClickOutside.propTypes = {
  onOutsideClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
