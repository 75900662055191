import React from 'react';
import { getMonth, getYear } from 'date-fns';
import useLocale from 'jsx/locale/useLocale';
import { monthStrings } from 'constants/dates';

export const MonthAxisTick = (props) => {
  const { x, y, payload } = props;
  const dt = new Date(payload.value);
  const month = getMonth(dt);
  const year = getYear(dt);
  const { lang } = useLocale();
  const months = monthStrings[lang];

  return (
    <g transform={`translate(${x},${y})`} className="text-12">
      <text x={0} y={0} dy={16} fill="#333" textAnchor="middle">
        {months[month]}
      </text>
      <text x={0} y={20} dy={16} fill="#333" textAnchor="middle">
        {year}
      </text>
    </g>
  );
};
