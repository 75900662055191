import React from 'react';
import useMinHeightForMainContent from '../hooks/useMinHeightForMainContent';

export default ({ children }) => {
  const height = useMinHeightForMainContent();
  return (
    <main className="main transition" role="main" style={{ minHeight: height + 'px' }}>
      {children}
    </main>
  );
};
