import React from "react";
import {
  Legend,
  BarChart,
  CartesianGrid,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { allColors } from "constants/colors";

export const MonitorIdentifierTotal = ({ data, onBarClick }) => {
  const [hovering, setHovering] = React.useState(-1);
  let res = {};
  data.forEach((entry) => {
    res[entry.label] = entry.value;
  });
  if (data.length === 0) return null;
  return (
    <div style={{ width: "100%", marginBottom: 30 }}>
      <h3 className="text-20 text-gray-dark">Aðilar í vöktun</h3>
      <ResponsiveContainer height={250} width="100%">
        <BarChart data={[res]} margin={{ top: 15, left: -10, right: 10 }}>
          <Tooltip
            labelFormatter={() => ""}
            cursor={{ fill: "rgba(0, 0, 0, 0.05)" }}
          />
          <CartesianGrid vertical={false} />
          <YAxis tickLine={false} axisLine={false} />
          <Legend wrapperStyle={{ marginBottom: -20 }} verticalAlign="bottom" />
          {data.map((entry, index) => {
            return (
              <Bar
                maxBarSize={80}
                onClick={() => onBarClick(entry)}
                onMouseEnter={() => setHovering(index)}
                onMouseLeave={() => setHovering(-1)}
                style={{
                  ...(hovering === index && {
                    cursor: "pointer",
                    opacity: 0.7,
                  }),
                }}
                key={index}
                fill={allColors[index % allColors.length]}
                dataKey={entry.label}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
