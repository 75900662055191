import React, { useState } from "react";
import FileSaver from "file-saver";
import ExcelExport from "jsx/elements/buttons/ExcelExport";
import { parseDate } from "utils/date";
import { api } from "api";
import { makeQueryFromState } from "helpers";
import Warning from "jsx/elements/messages/Warning";

export const ExcelExportButton = ({ state }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const clearError = () => setError(null);
  const { selectedMonitor } = state;
  const isAll = selectedMonitor === "all";

  async function downloadXlsx() {
    setLoading(true);

    try {
      const queryFromState = makeQueryFromState(state);
      const queryFromStateWithZeroCount = { ...queryFromState, count: 0 };

      const blob = await api.getSearchExcel(queryFromStateWithZeroCount);
      if (!blob.error) {
        FileSaver.saveAs(
          blob,
          `Creditinfo-Fjölmiðlar-${parseDate(new Date())}.xlsx`
        );
        clearError();
      } else {
        setError(blob.error.message);
      }
    } catch (e) {
      setError("Eitthvað fór úrskeiðis við að sækja skjal");
    }
    setLoading(false);
  }

  return (
    <>
      {error && (
        <div style={{ textAlign: "start" }}>
          <Warning
            messageType="error"
            messageHeader="Villa"
            messageBody={error}
            onClose={clearError}
          />
        </div>
      )}
      <ExcelExport
        onClick={downloadXlsx}
        disabled={
          (state.monitorData && state.monitorData.totalCount === 0) || isAll
        }
        loading={loading}
      />
    </>
  );
};
