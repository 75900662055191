import React from 'react';
import c from 'clsx';
import './Checkbox.less';

export const Checkbox = ({ label, checked, onClick, disabled }) => {
  return (
    <div className={c('__checkbox', { disabled })} onClick={disabled ? null : onClick}>
      <input type="checkbox" readOnly checked={checked || false} style={{ display: 'none' }} />
      <div className="__checkbox-spacer">
        <div className="__checkbox-border">
          <svg viewBox="0 0 20 20" className={c('__checkbox-svg', { checked })}>
            <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z" />
            <polyline points="4 11 8 15 16 6" />
          </svg>
        </div>
      </div>
      {label && <div className="__checkbox-label" data-checkbox-label="true" children={label} />}
    </div>
  );
};
