import React, { useState } from 'react';
import c from 'clsx';
import { fetchFile, getUrlAndRedirect } from 'helpers';

export const NewsArticleDownload = ({ item, monitorId }) => {
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  async function onClick() {
    if (item.hasUrl) {
      const success = await getUrlAndRedirect(monitorId, item.scriptId);
      if (!success) {
        setShowError(true);
      }
    } else {
      const success = await fetchFile(monitorId, item.scriptId, item.title);
      if (!success || success.error) {
        setShowError(true);
      }
    }
  }

  async function click() {
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  if (showError) {
    return <div className="half-spacing-top text-red">Ekki tókst að sækja frétt</div>;
  }

  return (
    <div
      className={c('inline-block half-spacing-top', {
        'text-gray-light': loading,
        'text-blue cursor-pointer': !loading,
        'icon-file-pdf': !item.hasUrl,
        'icon-new-tab': item.hasUrl
      })}
      onClick={loading ? null : click}
    >
      {loading ? ' Sæki frétt...' : item.hasUrl ? ' Opna á fréttamiðli' : ' Sækja frétt (PDF)'}
    </div>
  );
};
