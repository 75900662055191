import React from 'react';
import TextInput from 'jsx/elements/inputs/TextInput';
import Button from 'jsx/elements/buttons/Button';
import useLocale from 'jsx/locale/useLocale';
import Flex from 'jsx/layout/Flex';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import Trash from 'jsx/elements/buttons/Trash';
import Spinner from 'jsx/elements/spinner';
import Warning from 'jsx/elements/messages/Warning';
import { api } from 'api';

export const RequestAccess = () => {
  const [newRegno, setNewRegno] = React.useState('');
  const [newKeyword, setNewKeyword] = React.useState('');
  const [regnos, setRegnos] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);
  const [status, setStatus] = React.useState('init');
  const [message, setMessage] = React.useState('');

  const { user } = useAuthContext();
  const { t } = useLocale();

  const updateRegno = (_, val) => setNewRegno(val);
  const updateKeyword = (_, val) => setNewKeyword(val);
  const onKeywordTrash = (keyword) => setKeywords((old) => [...old.filter((k) => k !== keyword)]);
  const onRegnoTrash = (regno) => setRegnos((old) => [...old.filter((r) => r !== regno)]);

  const onRegnoAdd = () => {
    const regno = newRegno;
    setNewRegno('');
    const exists = regnos.includes(regno);
    if (exists) return;
    setRegnos((old) => [...old, regno]);
  };
  const onKeywordAdd = () => {
    const kw = newKeyword;
    setNewKeyword('');
    const exists = keywords.includes(kw);
    if (exists) return;
    setKeywords((old) => [...old, kw]);
  };

  const onSubmit = async () => {
    setStatus('loading');
    const res = await api.requestAccess({
      message,
      queries: regnos
      // queries: keywords
    });

    if (!res.error) {
      setStatus('success');
    } else {
      setStatus('error');
    }
  };
  const disableSubmit = (regnos.length === 0 && keywords.length === 0) || status === 'success';

  return (
    <>
      {status === 'loading' && <Spinner center />}
      <h1 className="text-28 text-gray-dark">{t('request_header')}</h1>

      {status === 'error' && (
        <Warning
          messageType="error"
          messageBody={t('request_error_body')}
          messageHeader={t('request_error_header')}
        />
      )}

      <p>{t('noaccess_cta')}</p>
      {status !== 'success' && <p>{t('request_info')}</p>}
      <h2 className="text-24 text-gray-dark">{t('request_sub_header')}</h2>

      <div className="grid collapse840">
        {/* <div className="col s1of3 half-spacing-top">
          <div className="text-18">{t('request_sub_company')}</div>
          <div className="text-gray half-spacing-top">{user.s_name}</div>
        </div>
        <div className="col s1of3 half-spacing-top">
          <div className="text-18">{t('request_sub_regno')}</div>
          <div className="text-gray half-spacing-top">{user.s_regno}</div>
        </div> */}
        <div className="col s1of3 half-spacing-top">
          <div className="text-18">{t('request_sub_user')}</div>
          <div className="text-gray half-spacing-top">{user.name}</div>
        </div>
      </div>

      {status === 'success' && (
        <div className="gutter-top">
          <Warning
            messageType="success"
            messageBody={t('request_success_body')}
            messageHeader={t('request_success_header')}
          />
        </div>
      )}

      {status !== 'success' && (
        <>
          <hr className="gutter-top gutter-bottom" />

          <h2 className="text-24 text-gray-dark">{t('request_data_header')}</h2>

          <div className="form">
            <div className="half-spacing-bottom">{t('request_identifiers')}</div>
            <Flex>
              <div style={{ flexGrow: 1 }}>
                <TextInput
                  value={newRegno}
                  onChange={updateRegno}
                  name="newRegno"
                  placeholder={t('request_identifier_placeholder')}
                />
              </div>
              <div className="half-spacing-left">
                <Button
                  disabled={!Boolean(newRegno)}
                  onClick={!Boolean(newRegno) ? null : onRegnoAdd}
                  text={t('request_add')}
                  icon="plus"
                  color="red"
                />
              </div>
            </Flex>
            {regnos.length > 0 && (
              <AddedRows>
                {regnos.map((regno) => (
                  <AddedRow key={regno}>
                    <div>{regno}</div>
                    <div className="text-20">
                      <Trash onClick={() => onRegnoTrash(regno)} />
                    </div>
                  </AddedRow>
                ))}
              </AddedRows>
            )}
          </div>

          {/* <div className="form gutter-top">
            <div className="half-spacing-bottom">{t('request_keywords')}</div>
            <Flex>
              <div style={{ flexGrow: 1 }}>
                <TextInput
                  value={newKeyword}
                  onChange={updateKeyword}
                  name="newKeyword"
                  placeholder={t('request_keyword_placeholder')}
                />
              </div>
              <div className="half-spacing-left">
                <Button
                  disabled={!Boolean(newKeyword)}
                  onClick={!Boolean(newKeyword) ? null : onKeywordAdd}
                  text={t('request_add')}
                  icon="plus"
                  color="red"
                />
              </div>
            </Flex>

            {keywords.length > 0 && (
              <AddedRows>
                {keywords.map((keyword) => (
                  <AddedRow key={keyword}>
                    <div>{keyword}</div>
                    <div className="text-20">
                      <Trash onClick={() => onKeywordTrash(keyword)} />
                    </div>
                  </AddedRow>
                ))}
              </AddedRows>
            )}
          </div> */}

          <div className="form gutter-top">
            <div className="half-spacing-bottom">{t('request_message')}</div>
            <textarea onChange={(e) => setMessage(e.target.value)} value={message} />
          </div>

          <div style={{ textAlign: 'right' }}>
            <Button
              disabled={disableSubmit}
              text={t('request_submit')}
              onClick={disableSubmit ? null : onSubmit}
              color="red"
            />
          </div>
        </>
      )}
    </>
  );
};

const AddedRows = (props) => (
  <div
    style={{
      borderTop: '1px solid #bfbfbf',
      marginTop: '1.5rem'
    }}
    {...props}
  />
);

const AddedRow = (props) => (
  <div
    style={{
      borderBottom: '1px solid #bfbfbf',
      height: '3rem',
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
      fontWeight: 'bold',
      justifyContent: 'space-between'
    }}
    {...props}
  />
);
