import React, { memo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useHasEnteredView } from 'hooks/useHasEnteredView';
import { allColors } from 'constants/colors';
import mediaMap from 'constants/mediaMap';

export const MediaBarChart = memo(({ data, title, onBarClick }) => {
  const [ref, hasEntered] = useHasEnteredView();
  if (data.length === 0) return null;
  return (
    <div style={{ width: '100%' }} ref={ref}>
      <h3 className="text-20 text-gray-dark">{title}</h3>
      <ResponsiveContainer width="100%" height={280}>
        <BarChart margin={{ top: 15, left: -10, right: 10 }} data={!hasEntered ? [] : data}>
          <Tooltip
            cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
            formatter={(_, __, { payload }) => [payload.value, 'Fjöldi']}
          />
          <CartesianGrid vertical={false} />
          <XAxis
            interval={0}
            dataKey="label"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedAxisTick />}
            height={60}
          />
          <YAxis axisLine={false} tickLine={false} />
          <Bar dataKey="value">
            {data.map((item, index) => (
              <Cell
                key={index}
                fill={allColors[index % allColors.length]}
                onClick={onBarClick ? () => onBarClick(item.id) : null}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});

const CustomizedAxisTick = (props) => {
  const [error, setError] = React.useState(false);
  const { x, y, payload } = props;
  let media = mediaMap[payload.value];
  if (error) {
    return (
      <g transform={`translate(${x - 17},${y + 10})`}>
        <svg id="icon-newspaper" viewBox="0 0 32 32" width="28" height="28">
          <path
            fill="#999"
            d="M28 8v-4h-28v22c0 1.105 0.895 2 2 2h27c1.657 0 3-1.343 3-3v-17h-4zM26 26h-24v-20h24v20zM4 10h20v2h-20zM16 14h8v2h-8zM16 18h8v2h-8zM16 22h6v2h-6zM4 14h10v10h-10z"
          />
        </svg>
      </g>
    );
  }

  const url = `${process.env.REACT_APP_NEWS_LOGO_ROOT}${
    media || payload.value.replace('.is', '')
  }.png`;

  return (
    <g transform={`translate(${x - 17},${y + 10})`}>
      <image onError={() => setError(true)} width="28" height="28" href={url} />
    </g>
  );
};
