import React from 'react';
import PropTypes from 'prop-types';

const Root = ({ sidebarOpen = false, children }) => {
  const rootCls = `thjonustuvefur ${sidebarOpen ? '  sidebar-open' : ''}`;
  return (
    <div className={`sidebar-on ${rootCls}`} style={{ position: 'relative' }}>
      <div id="main-wrap" className="main-wrap">
        <div className="top-spacer" />
        {children}
      </div>
    </div>
  );
};
export default Root;
Root.propTypes = {
  sidebarOpen: PropTypes.bool,
  children: PropTypes.node,
};
