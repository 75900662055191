import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import UM from 'jsx/layout/UserMenuV2';
import UMItem from 'jsx/layout/UserMenu/UserMenuItemV2';
import { useTranslation } from 'jsx/hooks/useTranslation';
import { sortBy } from 'utils/sorting';
import { navigationApi } from 'utils/api/shared';
import { ENVIRONMENT_URL_POSTFIX } from 'utils/constants/environment';

const settingsOringin = `https://fyrirtaeki${ENVIRONMENT_URL_POSTFIX[process.env.NODE_ENV]}.creditinfo.is`;
export const UserMenu = ({ open, toggleMenu }) => {
  const [products, setProducts] = useState([]);
  const { t } = useTranslation(2009);
  const { logOut, isAuthenticated, user } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) return;
    navigationApi.getSettingsUrls().then((res) => {
      if (res.error) return;
      setProducts(sortBy(res, 'levelOrder'));
    });
  }, [isAuthenticated]);

  return (
    <UM open={open} toggleOpen={toggleMenu}>
      <UMItem>
        <div className="logged-in-user">
          <span className="primary">{user && user.s_name}</span>
          <span className="secondary">{user && user.u_name}</span>
        </div>
      </UMItem>
      <UMItem iconCls="icon-user" link={`${settingsOringin}/account`} label={t('userMenu_editUser')} />

      {/* <UMItem iconCls="group">Þetta kemur úr shared api</UMItem> */}
      {products && products.length > 0 && (
        <UMItem>
          <div className="seperator"></div>
        </UMItem>
      )}
      {products.map((product) => (
        <UMItem
          iconCls={product.icon}
          link={product.url}
          key={product.navigationID}
          label={t(`Navigator.DisplayText.${product.navigationID}`) || product.displayText}
        />
      ))}
      <UMItem>
        <div className="seperator"></div>
      </UMItem>
      <UMItem
        label={t('userMenu_subscription')}
        iconCls="icon-administration"
        link={`${settingsOringin}/subscription`}
      />
      <UMItem label={t('userMenu_logout')} iconCls="icon-export" onClick={logOut} />
    </UM>
  );
};

UserMenu.propTypes = {
  open: PropTypes.bool,
  toggleMenu: PropTypes.func,
};
