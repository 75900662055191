import React, { memo } from 'react';
import { CollectionArticles } from './CollectionArticles';
import { CollectionMediaTotal } from './CollectionMediaTotal';
import { CollectionMediaTrend } from './CollectionMediaTrend';
import NoRecordsFound from 'jsx/elements/messages/NoRecordsFound';

export const Collection = memo(({ data, trend, searchString, count }) => {
  if (!data) return null;

  return (
    <>
      <h2 className="text-24 text-gray-dark gutter-top">
        {/* Fréttir úr safni {data.totalCount > 0 && `- alls ${data.totalCount}`} */}
        Greinar úr safni {count <= data.totalCount ? `${count} af` : ''} {data.totalCount}
      </h2>
      {data.totalCount === 0 && <NoRecordsFound />}
      <div style={{ display: 'flex' }}>
        {trend && trend.mediaTrend && trend.mediaTrend.length > 0 && (
          <CollectionMediaTotal data={trend.mediaTrend} />
        )}
        {trend && trend.coverageTrend && trend.coverageTrend.length > 1 && (
          <CollectionMediaTrend data={trend.coverageTrend} searchString={searchString} />
        )}
      </div>
      {data.scripts.length > 0 && (
        <div className="gutter-top">
          <CollectionArticles articles={data.scripts} />
        </div>
      )}
    </>
  );
});
