import React, { memo } from 'react';
import { Select } from 'components/Select';
import { Search } from 'components/Search';
import { StarIcon } from 'components/StarIcon';
import { useRoleAccess } from 'jsx/hooks';
import { useMedia } from 'hooks/useMedia';

const analysisItems = [
  { display: 'Jákvætt', value: '1' },
  { display: 'Hlutlaust', value: '0' },
  { display: 'Neikvætt', value: '-1' },
  { display: 'Jafnað', value: '100' }
];

const scoreItems = [
  { display: '1', value: '1' },
  { display: '2', value: '2' },
  { display: '3', value: '3' },
  { display: '4', value: '4' },
  { display: '5', value: '5' }
];

export const OverviewFilters = memo((props) => {
  const hasAnalysis = useRoleAccess(805);
  const hasScore = useRoleAccess(807);
  const {
    monitors,
    selectedMonitor,
    monitorInfo,
    fromDate,
    toDate,
    search,
    filteredIdentifiers,
    filteredMedias,
    filteredAnalysis,
    filteredScore,
    globalMediaList
  } = props.state;
  const {
    onMonitorSelect,
    onRegnoChange,
    onMediaChange,
    onAnalysisChange,
    onDateChange,
    onScoreChange,
    onSearchChange
  } = props.actions;

  const isSocialMedia = monitorInfo && monitorInfo.monitor.monitorTypeId === 11;
  const isAll = selectedMonitor === 'all';
  const isMobile = useMedia('(max-width: 840px)');
  return (
    <>
      <div className="grid tight collapse720">
        <div className="col s4of10 half-spacing-bottom">
          <Select
            items={monitors}
            defaultValue={selectedMonitor}
            onChange={onMonitorSelect}
            getDisplayTitle={(val) => `Leit í: ${val}`}
          />
        </div>
        <div className="col s6of10 half-spacing-bottom">
          <Search value={search} onChange={onSearchChange} disabled={isSocialMedia} />
        </div>
      </div>

      <div className="grid tight collapse840">
        <div className="grid col s4of10 collapse600">
          <div className="col s1of2" style={{ paddingRight: isMobile ? 0 : 10 }}>
            <Select
              title="Miðlar"
              variant="multi"
              items={isAll ? globalMediaList : monitorInfo ? monitorInfo.medias || [] : []}
              disabled={
                (!monitorInfo || monitorInfo.medias.length === 0 || isSocialMedia) && !isAll
              }
              defaultValue={filteredMedias}
              onChange={onMediaChange}
              isSearch
            />
          </div>
          <div className="col s1of2" style={{ paddingLeft: isMobile ? 0 : 10 }}>
            <Select
              title="Tímabil"
              variant="date-multi"
              defaultValue={{ from: fromDate, to: toDate }}
              onChange={onDateChange}
            />
          </div>
        </div>
        <div className="grid col s6of10 collapse840">
          <div className="col s1of3">
            <Select
              title="Afmarka við ..."
              variant="multi"
              defaultValue={filteredIdentifiers}
              items={monitorInfo ? monitorInfo.identifiers || [] : []}
              disabled={
                isAll || !monitorInfo || monitorInfo.identifiers.length <= 1 || isSocialMedia
              }
              onChange={onRegnoChange}
              isSearch
            />
          </div>
          <div className="col s1of3" style={{ paddingLeft: isMobile ? 0 : 20 }}>
            <Select
              disabled={isAll || !monitorInfo || !hasAnalysis || isSocialMedia}
              title="Innihaldsgreining"
              defaultValue={filteredAnalysis}
              variant="multi"
              items={analysisItems || []}
              onChange={onAnalysisChange}
            />
          </div>
          <div className="col s1of3" style={{ paddingLeft: isMobile ? 0 : 20 }}>
            <Select
              title="Fréttaskor"
              variant="multi"
              defaultValue={filteredScore}
              disabled={isAll || !monitorInfo || !hasScore || isSocialMedia}
              onChange={onScoreChange}
              items={scoreItems || []}
              getDisplayTitle={(val) => `Skor: ${val}`}
              getItemDisplay={(val) => (
                <span>
                  {val}
                  {Array.from({ length: Number(val) }).map((_, i) => (
                    <StarIcon key={i} style={{ marginLeft: i === 0 ? 4 : 2 }} />
                  ))}
                </span>
              )}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '0px',
          marginBottom: '5px',
          flexWrap: 'nowrap'
        }}
      >
        <Select title="Tímabil" variant="tag" defaultValue={{ from: fromDate, to: toDate }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: '15px',
            marginBottom: '15px',
            flexWrap: 'wrap'
          }}
        >
          <Select
            title="Miðlar"
            variant="tag"
            items={isAll ? globalMediaList : monitorInfo ? monitorInfo.medias || [] : []}
            defaultValue={filteredMedias}
            onChange={onMediaChange}
          />
          <Select
            title="Afmarka við ..."
            variant="tag"
            defaultValue={filteredIdentifiers}
            items={monitorInfo ? monitorInfo.identifiers || [] : []}
            disabled={isAll || !monitorInfo || monitorInfo.identifiers.length <= 1 || isSocialMedia}
            onChange={onRegnoChange}
          />
          <Select
            title="Innihaldsgreining"
            variant="tag"
            disabled={isAll || !monitorInfo || !hasAnalysis || isSocialMedia}
            defaultValue={filteredAnalysis}
            items={analysisItems || []}
            onChange={onAnalysisChange}
          />
          <Select
            title="Fréttaskor"
            variant="tag"
            defaultValue={filteredScore}
            disabled={isAll || !monitorInfo || !hasScore || isSocialMedia}
            onChange={onScoreChange}
            items={scoreItems || []}
            getDisplayTitle={(val) => `Skor: ${val}`}
            getItemDisplay={(val) => (
              <span>
                {val}
                {Array.from({ length: Number(val) }).map((_, i) => (
                  <StarIcon key={i} style={{ marginLeft: i === 0 ? 4 : 2 }} />
                ))}
              </span>
            )}
          />
        </div>
      </div>
    </>
  );
});
