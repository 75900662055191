import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import 'styles/less/components/jsx-tooltip.less';

class Tooltip extends React.Component {
  componentDidMount() {
    ReactTooltip.rebuild();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const { id, place, effect } = this.props;
    return ReactDOM.createPortal(
      <ReactTooltip id={id} className="jsx-tooltip text-center" place={place} effect={effect} />,
      document.body
    );
  }
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  effect: PropTypes.oneOf(['solid', 'float'])
};

Tooltip.defaultProps = {
  place: 'top',
  effect: 'solid'
};

export default Tooltip;
