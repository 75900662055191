import React from 'react';
import { useParams } from 'react-router';
import { getUrlAndRedirect } from 'helpers';

export const MonitorScriptUrl = () => {
  const { monitorID, scriptID } = useParams();

  React.useEffect(() => {
    getUrlAndRedirect(monitorID, scriptID, false);
  }, [monitorID, scriptID]);

  return null;
};
