import React from 'react';
import PropTypes from 'prop-types';

const Warning = ({ messageType, messageHeader, messageBody, extraClass, onClose }) => {
  return (
    <div className={`message ${messageType} ${extraClass}`}>
      <div className="message__header">
        <h1>{messageHeader}</h1>
        {onClose && <div className="message-close icon-close" onClick={onClose} />}
      </div>
      {messageBody !== '' && (
        <div className="message__content">
          {typeof messageBody === 'string' ? <p>{messageBody}</p> : messageBody}
        </div>
      )}
    </div>
  );
};

Warning.propTypes = {
  messageType: PropTypes.string,
  messageHeader: PropTypes.string,
  messageBody: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extraClass: PropTypes.string,
  onClose: PropTypes.func
};

Warning.defaultProps = {
  messageType: '',
  messageHeader: '',
  messageBody: '',
  extraClass: ''
};

export default Warning;
