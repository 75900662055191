import React from 'react';
import { SearchIcon } from 'icons/SearchIcon';
import { Input } from 'components/Input';

export const Search = (props) => {
  const { value, onChange, placeholder = '', disabled } = props;
  return (
    <div style={{ position: 'relative' }}>
      <Input
        tabIndex={0}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '0.5rem',
          ...(disabled && {
            opacity: 0.5,
          }),
        }}
      >
        <SearchIcon />
      </div>
    </div>
  );
};
