import React from 'react';
import { withRouter } from 'react-router';

function ScrollToTop({ children, location }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children || null;
}

export default withRouter(ScrollToTop);
