import React from 'react';

const PrintButton = () => {
  return (
    <button
      className="button icon-print no-text gray-light no-print"
      onClick={() => window.print()}
    />
  );
};

export default PrintButton;
