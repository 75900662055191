import { useState, useEffect } from 'react';
import { getVisibleHeight, getVisibleWidth, debounce } from '../../utils/browser';

function getSize() {
  return { height: getVisibleHeight(), width: getVisibleWidth() };
}

/**
 * Hook that watches and returns the window size (height and width).
 * Debounces the function call for 100ms in case the user is rapidly
 * dragging the window we dont need to re-render every tick.
 */
export default function useWindowSize() {
  let [windowSize, setWindowSize] = useState(getSize());

  const handleResize = debounce(() => {
    setWindowSize(getSize());
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
