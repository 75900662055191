import React from 'react';
import ReactGA from 'react-ga';
import { history } from '../routing/history';
import { useCookieState } from '../auth/CookieProvider';

const USE_ANALYTICS = process.env.REACT_APP_USE_ANALYTICS === 'yes';

export const useGoogleAnalytics = (id) => {
  if (!USE_ANALYTICS) return;
  const cookieState = useCookieState();

  if (cookieState === undefined) {
    throw new Error('You need to wrap the app in a CookieProvider in order to use google analytics!');
  }

  React.useEffect(() => {
    if (!cookieState.analytics || !id) return;

    ReactGA.initialize(id, { debug: false, titleCase: false });
    return history.listen((location) => ReactGA.pageview(location.pathname + location.search));
  }, [id, cookieState.analytics]);
};

export const gaEvent = ({ category, action, label }) => {
  if (!USE_ANALYTICS) return;
  // const cookieState = useCookieState();

  // if (!cookieState.analytics) return;
  return ReactGA.event({
    category: category || 'Button',
    action: action || 'click',
    label: label || 'Unknown',
  });
};
