import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './MenuButton.less';
// import { useUserMenuOpenToggleCtx, UserMenuOpenToggleCtx } from '../UserMenuV2';

const MenuButton = ({ icon, label, onClick, isMenu = true, open, menu }) => {
  // const [isOpen, setOpen] = useState(open);
  // const action = () => {
  //   isMenu && toggleMenu();
  //   onClick();
  // };
  // const toggleMenu = () => {
  //   setOpen(!isOpen);
  // };
  if (menu) {
    return (
      <>
        <div className="menu-drop no-print">
          <button className={clsx('menu-button', open && 'open')} onClick={onClick}>
            <i className={clsx('button-icon', icon)}></i>
            <span className="label">{label}</span>
            {isMenu && <i className="menu icon-caret-down"></i>}
          </button>
          {menu}
        </div>
      </>
    );
  }

  return (
    <>
      <button className={clsx('menu-button no-print', open && 'open')} onClick={onClick}>
        <i className={clsx('button-icon', icon)}></i>
        <span className="label">{label}</span>
        {isMenu && <i className="menu icon-caret-down"></i>}
      </button>
      {menu}
    </>
  );
};

MenuButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isMenu: PropTypes.bool,
  open: PropTypes.bool,
};

export default MenuButton;
