import React from "react";
import {
  BarChart,
  Legend,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useHasEnteredView } from "hooks/useHasEnteredView";
import { analysisColors } from "constants/colors";
import { scoreIdtoTitle, keyLables } from "constants/score";

export const MonitorScoreAndAnalysis = ({ data: userData }) => {
  const [ref, hasEnteredView] = useHasEnteredView();
  const data = Object.keys(userData)
    .filter((key) => key !== "0")
    .map((key) => {
      const obj = userData[key];
      let res = {};
      Object.keys(obj).forEach((innerKey) => {
        const inner = userData[key][innerKey];
        // const label = inner.id === -1 ? 'Hlutlaust' : inner.label;
        res[keyLables[inner.id]] = inner.value;
      });
      res.yvalue = key;
      return res;
    });
  if (data.length <= 1) return null;
  return (
    <div ref={ref} style={{ width: "100%" }}>
      <h3 className="text-20 text-gray-dark">
        Fréttaskor og innihaldsgreining
      </h3>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={hasEnteredView ? data : []}
          margin={{ top: 20, right: 10, left: 20 }}
          layout="vertical"
        >
          <XAxis
            tickLine={false}
            type="number"
            label="Fjöldi frétta"
            height={70}
          />
          <Tooltip
            labelFormatter={(val) => scoreIdtoTitle[val + 1]}
            cursor={{ fill: "rgba(0, 0, 0, 0.05)" }}
          />
          <Legend />
          <YAxis
            tickLine={false}
            axisLine={false}
            type="category"
            dataKey={"yvalue"}
            tick={<CustomizedAxisTick />}
            label={{ value: "Fréttaskor", position: "top" }}
          />
          <Bar dataKey="Neikvætt" stackId="a" fill={analysisColors[-1]} />
          <Bar dataKey="Hlutlaust" stackId="a" fill={analysisColors[0]} />
          <Bar dataKey="Jafnað" stackId="a" fill={analysisColors[100]} />
          <Bar dataKey="Jákvætt" stackId="a" fill={analysisColors[1]} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x - 10},${y - 5})`} className="text-12">
      <text x={0} y={0} dy={9} fill="#333" textAnchor="middle">
        {payload.value}
      </text>
      <svg
        width="30"
        height="60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform={`translate(5, 0)`}
          d="M10 3.877l-3.455-.502L5 .245l-1.545 3.13L0 3.877l2.5 2.437-.59 3.441L5 8.131l3.09 1.624-.59-3.44L10 3.876z"
          fill="currentColor"
        />
      </svg>
    </g>
  );
};
