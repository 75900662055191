import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import 'styles/less/components/jsx-tooltip.less';

export const Tooltip = ({ id, place, effect }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <>
      {ReactDOM.createPortal(
        <ReactTooltip id={id} className="jsx-tooltip text-center" place={place} effect={effect} />,
        document.body
      )}
    </>
  );
};

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  effect: PropTypes.oneOf(['solid', 'float']),
};

Tooltip.defaultProps = {
  place: 'top',
  effect: 'solid',
};
