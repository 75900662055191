import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useAuthContext } from 'jsx/auth/AuthProviderV2';
import Section from 'jsx/elements/section/Section';
import { Root, MainContent, Header, Footer, Sidebar } from 'jsx/layout';
import { useRemoveInitApp, useSidebarProducts, useTranslationApi, useToggle, useRoleAccess } from 'jsx/hooks';
import useTopProducts from 'hooks/useTopProducts';
import { api } from 'api';
// import { UserMenu } from 'components/UserMenu';
import { NoAccess } from 'components/NoAccess';
import { RequestAccess } from 'components/RequestAccess';
import { MonitorScriptUrl } from './MonitorScriptUrl';
import { MonitorScriptFile } from './MonitorScriptFile';
import { MonitorSettings } from 'components/MonitorSettings';
import { Overview } from 'routes/Overview';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicScript } from './public/PublicScript';
import { LanguageButton } from 'jsx/elements/buttons/LanguageButton';
import MenuButton from 'jsx/layout/MenuButton/MenuButton';
import { UserMenu } from 'jsx/elements/UserMenu/UserMenu';
import PageHeader from 'jsx/layout/PageHeaderV2';
import { PAGE_NAV } from 'constants/products';
import useProductBreads from 'jsx/hooks/useProductBreadsNoState';
import Button from 'jsx/elements/buttons/Button';

// import { Routes } from './Routes';

export function App() {
  const { isAuthenticated } = useAuthContext();
  const [sidebarOpen, toggleSidebar] = useToggle(true);
  const [userMenuOpen, toggleUserMenu] = useToggle();
  const [monitors, setMonitors] = React.useState(null);
  const loaded = useTranslationApi(2014);
  useRemoveInitApp(loaded);
  const { user } = useAuthContext();
  const sidebarProducts = useSidebarProducts(801);
  const hasProductAccess = useRoleAccess(801);
  // const isPilotCustomer = isAuthenticated ? pilotCustomers.includes(Number(user.s_id)) : false;
  const topProducts = useTopProducts(hasProductAccess); //
  const breads = useProductBreads(PAGE_NAV);
  const userName = isAuthenticated ? user.name : '';
  const subscriberName = isAuthenticated ? user.s_name : '';

  useEffect(() => {
    if (hasProductAccess) {
      api.getMonitors().then((data) => {
        setMonitors(data.error ? [] : data);
      });
    } else {
      setMonitors([]);
    }
  }, [hasProductAccess]);

  if (!loaded) return null;

  return (
    <Root sidebarOpen={sidebarOpen}>
      <Header
        showMobileNav
        toggleSidebar={toggleSidebar}
        // products={topProducts}
        userMenu={isAuthenticated && <UserMenu open={userMenuOpen} toggleMenu={toggleUserMenu} />}
      >
        {isAuthenticated && <LanguageButton />}
        {isAuthenticated && <MenuButton icon="icon-user" label={subscriberName} onClick={toggleUserMenu} open={userMenuOpen} />}
      </Header>

      {/* <Header
        mainText={userName}
        subText={subscriberName}
        showMobileNav
        toggleSidebar={toggleSidebar}
        toggleUserMenu={toggleUserMenu}
        products={topProducts}
        userMenu={<UserMenu open={userMenuOpen} toggleMenu={toggleUserMenu} />}
      /> */}
      <MainContent>
        <PageHeader
          products={isAuthenticated && PAGE_NAV}
          fixedTitle={!isAuthenticated && 'Fjölmiðlar'}
          rightActions={
            !isAuthenticated && (
              <a className="button" href="/">
                Fleiri fréttir
              </a>
            )
          }
          breadCrumbs={isAuthenticated && breads}
        ></PageHeader>
        <Section>
          {isAuthenticated && <Content monitors={monitors} hasProductAccess={hasProductAccess} />}

          {!isAuthenticated && <OpenRoutes />}
        </Section>
      </MainContent>
      {/* <Footer /> */}
      <Sidebar products={sidebarProducts} toggleExpanded={toggleSidebar} isExpanded={sidebarOpen} showNested={true} />
    </Root>
  );
}

const Content = ({ hasProductAccess, monitors, isAuthenticated }) => {
  // console.log(hasProductAccess, monitors, pilot, isAuthenticated);

  if (hasProductAccess && monitors === null) return null;

  if (hasProductAccess) {
    return (
      <Switch>
        <Route
          path="/news/:scriptID+"
          render={(props) => {
            const regex = /\//g;
            const pathname = props.location.pathname;
            var splitStr = pathname.split('news/c=');

            if (splitStr && splitStr.length > 1 && splitStr[1].match(regex)) {
              const endcodedC = splitStr[1].replace(regex, '%2F');
              const endcodedPath = `${splitStr[0]}news/c=${endcodedC}`;
              return <Redirect to={endcodedPath} />;
            } else {
              return <PublicScript />;
            }
          }}
        />
        {/* <Route path="/news/:scriptID+">
          <PublicScript />
        </Route> */}

        <ProtectedRoute path="/monitorscripturl/:monitorID/:scriptID">
          <MonitorScriptUrl />
        </ProtectedRoute>

        <ProtectedRoute path="/monitorscriptfile/:monitorID/:scriptID">
          <MonitorScriptFile />
        </ProtectedRoute>

        <ProtectedRoute path="/settings">
          <MonitorSettings />
        </ProtectedRoute>

        <ProtectedRoute path="/" exact>
          <Overview monitors={monitors} hasProductAccess={hasProductAccess} />
        </ProtectedRoute>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <ProtectedRoute path="/" exact>
          <NoAccess />
        </ProtectedRoute>
        <ProtectedRoute path="/request-access">
          <RequestAccess />
        </ProtectedRoute>
      </Switch>
    );
  }

  // console.log(hasProductAccess, monitors, pilot, isAuthenticated);
};

const OpenRoutes = () => {
  return (
    <Switch>
      <Route
        path="/news/:scriptID+"
        render={(props) => {
          const regex = /\//g;
          const pathname = props.location.pathname;
          var splitStr = pathname.split('news/c=');

          if (splitStr && splitStr.length > 1 && splitStr[1].match(regex)) {
            const endcodedC = splitStr[1].replace(regex, '%2F');
            const endcodedPath = `${splitStr[0]}news/c=${endcodedC}`;
            return <Redirect to={endcodedPath} />;
          } else {
            return <PublicScript />;
          }
        }}
      />
      <ProtectedRoute path="/" exact>
        <NoAccess />
      </ProtectedRoute>
      <ProtectedRoute path="/request-access">
        <RequestAccess />
      </ProtectedRoute>
    </Switch>
  );
};
