import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Tooltip from '../../elements/messages/Tooltip';
import ReactTooltip from 'react-tooltip';

const ItemWithLabel = ({ open, it, txt }) => (
  <div className="label" style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ marginRight: it.announceLabel ? 4 : 0 }}>{txt}</div>
    {open && it.announceLabel && (
      <div
        style={{
          backgroundColor: '#00a6c6',
          padding: '0px 5px 2px 5px',
          color: '#fff',
          height: 20
        }}
      >
        <div className="text-12 text-semibold" style={{ textTransform: 'uppercase' }}>
          {it.announceLabel}
        </div>
      </div>
    )}
  </div>
);

export default ({ items, mobile, isOpen, showNested }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  if (!items) return null;

  return (
    <ul className="sidebar-nav">
      {items.map((it, index) => {
        const fixedUrl = it.url
          .split('/')
          .slice(0, 4)
          .join('/'); //Needed for sub application like fyrirtaeki.creditinfo.is/account
        const selected = window.location.href.includes(fixedUrl);
        let el = (
          <a
            href={it.url}
            className={selected ? 'nav-link selected' : 'nav-link'}
            data-for={`sidebarTip-${it.label}`}
            data-tip={it.label}
          >
            {!isOpen && <Tooltip id={`sidebarTip-${it.label}`} place="right" />}
            <i className={`icon icon-${it.icon}`} />
            <ItemWithLabel txt={it.label} it={it} open={isOpen} />
          </a>
        );
        if (it.url.startsWith('/')) {
          el = (
            <NavLink
              exact={it.exact || false}
              className="nav-link"
              to={it.url}
              activeClassName="selected"
              data-for={`sidebarTip-${it.label}`}
              data-tip={it.label}
            >
              {!isOpen && <Tooltip id={`sidebarTip-${it.label}`} place="right" />}
              {typeof it.icon === 'string' && <i className={`icon icon-${it.icon}`} />}
              {typeof it.icon !== 'string' && it.icon}
              <span
                style={{ position: 'absolute', left: 35, top: 5 }}
                id={`sidebar-notification-${it.id}`}
              />
              <ItemWithLabel it={it} txt={it.label} open={isOpen} />
            </NavLink>
          );
        }
        return (
          <li key={'navitem' + index} className="nav-item">
            {el}
            {(mobile || showNested) && it.children && it.children.length > 0 && (
              <ul className="level-2" style={{ ...(showNested && isOpen && { display: 'block' }) }}>
                {it.children.map((h, i) => {
                  return (
                    <li key={'nav-sub-item' + h.url} className="nav-item">
                      <NavLink
                        exact={h.exact || false}
                        className="nav-link"
                        to={h.url}
                        activeClassName="selected"
                      >
                        <ItemWithLabel it={it} txt={h.label} open={isOpen} />
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};
