import useSharedSidebarProducts from './useSharedSidebarProducts';
/**
 *
 * @param {Array} appProducts  [] : App products from local const.
 * @param {Boolean} useShared  true : Use shared products from shared api
 */

export default function useSidebarProducts(appProducts = [], useShared = true) {
  let products = useShared ? useSharedSidebarProducts() : [];
  if (appProducts.length) {
    products.map((product) => {
      appProducts.map((p) => {
        if (p.id === product.navigationID) {
          if (p.useLocal) {
            product.displayText = p.label;
            product.hintText = p.label;
            product.icon = `icon ${p.icon}`;
            product.url = p.url;
          }
          product['children'] = p.children ? p.children : [];
        }
      });
    });
  }
  return products;
}
