import React from 'react';
import PropTypes from 'prop-types';
import { splitSsn } from 'utils/strings';
import PrintButton from '../buttons/PrintButton';
import './SectionTitle.less';

const SectionTitle = ({ text, name, regno, children, showPrint }) => {
  const textOrName = text ? (
    text.typeOf === 'string' ? (
      <div>{text}</div>
    ) : (
      text
    )
  ) : (
    <div>
      <div className="inner--left-name">{name}</div>
      {regno && <div className="inner--left-regno">({splitSsn(regno)})</div>}
    </div>
  );

  return (
    <div id="section-title" className="gutter-bottom">
      <div className="inner--left">
        <h2 className="no-gutter-bottom">{textOrName}</h2>
      </div>
      <div className="inner--right">
        {children}
        {showPrint && (
          <div className="inner--right-print no-print">
            <PrintButton />
          </div>
        )}
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  regno: PropTypes.string,
  children: PropTypes.node,
  showPrint: PropTypes.bool,
};

export default SectionTitle;
