import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const BreadCrumbs = ({ products = [], options }) => {
  if (products === undefined) return null;

  let breadOptions = options || {};
  const breadcrumbs = useBreadcrumbs(products, breadOptions || []);
  if (!breadcrumbs.length) return null;
  return (
    <div className="bread-crumbs no-print">
      {breadcrumbs.map(({ match, breadcrumb }, i) => (
        <CrumbItem url={match.url} caption={breadcrumb.props.children} isSep={i < breadcrumbs.length - 1} key={i} />
      ))}
    </div>
  );
};
BreadCrumbs.propTypes = {
  products: PropTypes.array,
  options: PropTypes.object,
};
export default BreadCrumbs;

const CrumbItem = ({ url, caption, isSep }) => {
  return (
    <>
      <div className="bread-crumb">
        <NavLink to={url} activeClassName="current">
          {caption}
        </NavLink>
      </div>
      {isSep && <BreadCrumbSeparator />}
    </>
  );
};

const BreadCrumbSeparator = () => {
  return <div className="bread-sep">/</div>;
};

CrumbItem.propTypes = {
  url: PropTypes.string,
  caption: PropTypes.string,
  isSep: PropTypes.bool,
};
