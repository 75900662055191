import axios from 'axios';

export default (url, options = {}) => {
  const config = {
    url,
    method: options.method || 'get',
    responseType: options.responseType || 'json',
    headers: options.headers || {},
  };

  config.headers['Content-Type'] = options.contentType || 'application/json';

  if (!!window.MSInputMethodContext && !!document.documentMode) {
    /**
     * Internet Explorer is fucking stupid. If I dont set these headers,
     * many GET requests are just cached by IE11. That means
     * certain conditionals keep firing even if the user
     * has resolved them because a GET request to an endpoint
     * that was requested recently, still shows the same response
     * even if the user made some changes in the meantime......
     */
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
  }

  if (options.data) {
    if (options.formData) {
      let fd = new FormData();
      fd.append(options.fieldName || 'file', options.data);
      config.data = fd;
    } else {
      if (!options.contentType) {
        config.data = JSON.stringify(options.data);
      } else {
        config.data = options.data;
      }
    }
  }

  return axios(config)
    .then((response) => {
      return response.data !== undefined ? response.data : response;
    })
    .catch((err) => {
      let response = err.response;
      let error = {
        code: -1,
        message: 'Villa kom upp.',
      };
      if (response) {
        error.status = response.status;
        error.statusText = response.statusText;
        if (response.data) {
          // Handling blob responses
          if (response.data instanceof Blob) {
            return response.data.text().then((text) => {
              try {
                const jsonData = JSON.parse(text)
                return {
                  error: {
                    ...error,
                    ...jsonData,
                    message: jsonData.error,
                  },
                }
              } catch (parseError) {
                console.error("Error parsing JSON from Blob:", parseError)
                return { error }
              }
            })
          } else {
            error.error = response.data.error;
            error.error_id = response.data.error_id;
            error.logID = response.data.logID;
            error.error_details = response.data.error_details;
            error.error_description = response.data.error_description;
          }
        }
      }
      return {
        error,
      };
    });
};
