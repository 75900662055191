import FileSaver from 'file-saver';
import apiRequest from './apiRequest';

const base64ToArrayBuffer = (base64) => {
  let binaryString = window.atob(base64);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const b64ToPdfFile = (data, filename, saveAs = true, binary = false) => {
  if (data !== undefined && data !== null) {
    let ab = base64ToArrayBuffer(data);
    if (binary) return ab;
    let blob = new Blob([ab], { type: 'application/pdf' });
    let isPdf = /.pdf/gi;
    const name = filename.match(isPdf) ? filename : filename + '.pdf';
    if (saveAs) {
      FileSaver.saveAs(blob, name);
    } else {
      return blob;
    }
  }
};

export default (url, filename) => {
  apiRequest(url).then((data) => {
    b64ToPdfFile(data, filename);
  });
};
