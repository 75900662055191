import React from 'react';
import c from 'clsx';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.less';

export const DatePicker = (props) => {
  const dpRef = React.useRef();
  const { value, onChange, className, ...rest } = props;
  return (
    <ReactDatePicker
      ref={dpRef}
      onChange={onChange}
      selected={value}
      // onSelect={onChange}
      dateFormat="dd/MM/yyyy"
      className={c('__datepicker', className)}
      {...rest}
      //
      // Ghetto way to close it since we dont want the out side click to fire,
      // because it will close any overlay the datepicker is placed inside.
      // 150ms is so the animation of selecting a date will finish before closing.
      //
      // onSelect={() => {
      //   setTimeout(() => {
      //     if (dpRef.current) {
      //       dpRef.current.setOpen(false);
      //     }
      //   }, 150);
      // }}
      shouldCloseOnSelect={false}
    />
  );
};
