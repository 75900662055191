import React from 'react';
import PropTypes from 'prop-types';

import ShowInPrint from '../elements/text/ShowInPrint';
import useToggle from '../hooks/useToggle';

// import UserInformation from './Header/UserInformation';
import UserMenu from './UserMenuV2';

const printLogo = process.env.REACT_APP_STATIC_IMG_URL + '/logo/creditinfo-print-logo.png';
const ciLogo = process.env.REACT_APP_STATIC_IMG_URL + '/logo/brand-ci-icon.png';

const Header = ({
  showMobileNav = true,
  toggleSidebar,
  userMenu = null,
  userMenuItems = [],
  // toggleUserMenu = null,
  children,
}) => {
  const hasUserMenu = userMenu !== null || userMenuItems.length > 0;
  const [isOpen, toggleOpen] = useToggle(false);

  if (hasUserMenu && userMenu === null) {
    userMenu = <UserMenu items={userMenuItems} open={isOpen} toggleOpen={toggleOpen} />;
  }

  return (
    <>
      {/* <div className="brand">
        <a href="/" title="">
          <img className="logo-text" src={ciLogo} alt="Creditinfo" />
        </a>
        <ShowInPrint>
          <img src={printLogo} alt="Creditinfo" />
        </ShowInPrint>
      </div> */}
      <header id="main-header" className="main-header">
        <div className="grid" style={{ height: '100%' }}>
          <div className="col s4of6 logo-print-margin" style={{ height: '100%' }}>
            <a href="/" title="" className="no-print">
              <img className="logo" src={ciLogo} alt="Creditinfo" />
            </a>
            <ShowInPrint>
              <img src={printLogo} alt="Creditinfo" />
            </ShowInPrint>
          </div>
          <div
            className="col s2of6 no-print"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingRight: 30,
            }}
          >
            {children}
            {showMobileNav && (
              <button className="mobilenav-toggle button icon-grid no-text white" onClick={toggleSidebar}></button>
            )}
          </div>
        </div>
        {userMenu}
      </header>
    </>
  );
};

export default Header;

Header.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  products: PropTypes.array,
  showMobileNav: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  userMenu: PropTypes.node,
  userMenuItems: PropTypes.array,
  toggleUserMenu: PropTypes.func,
  children: PropTypes.node,
};
